import { BulkCommitment, BulkCommitmentDetails } from '@api';
import {
    ArrowDropUp, CheckCircleOutline,
    ExpandLess, ExpandMore, HourglassBottom, HourglassTop, Lock, Upload
} from '@mui/icons-material';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grow,
    IconButton,
    LinearProgress,
    MenuItem,
    Link as MuiLink,
    Paper,
    Popover,
    TextField, Tooltip, Typography, TypographyVariant, useTheme
} from '@mui/material';
import {
    ExpandableHeader, FilterTextField, IconTypography, RoutedDialogManager
} from '@tsp-ui/core/components';
import {
    formatCurrency,
    formatCurrencyAbbreviation
} from '@tsp-ui/core/utils';
import { getGradient } from '@utils/color-utils';
import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import BulkCommitmentDialog from '@views/admin/bulk-commitment/components/BulkCommitmentDialog';
import Page from '@views/components/Page';
import { PricingTierTrackingCard } from '@views/tracking/bulk-commitment/BulkCommitmentTrackingDetailPage';
import clsx from 'clsx';
import {
    formatDistanceToNowStrict, isAfter, isBefore, parseISO
} from 'date-fns';
import {
    DragEvent,
    ReactNode, useContext, useMemo, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { useDebounce } from 'use-debounce';

import styles from './BulkCommitmentTrackingPage.module.scss';


// TODO post-demo refactor this to share with BulkCommitmentManagementPage
export default function BulkCommitmentTrackingPage() {
    const { bulkCommitments, loading } = useContext(BulkCommitmentsContext);

    const [ selectedCustomerId, setSelectedCustomerId ] = useState('ALL');
    const [ filterText, setFilterText ] = useState('');
    const [ showPastDeliveries, setShowPastDeliveries ] = useState(false);

    const commitmentsByCustomerId = bulkCommitments
        ?.filter(({ id, deliveryExpiration }) => (
            id.toString().includes(filterText) && (
                showPastDeliveries || isAfter(parseISO(deliveryExpiration), new Date())
            )
        ))
        .reduce((value, commitment) => {
            if (!selectedCustomerId || commitment.customerId === selectedCustomerId) {
                value[commitment.customerId] = [
                    ...(value[commitment.customerId] || []),
                    commitment
                ];
            }

            return value;
        }, {} as { [customerId: string]: BulkCommitment[]});

    return (
        <Page
            header="Bulk Commitment Tracking"
            loading={loading}
            headerActions={(
                <Button
                    component={Link}
                    to="../../../admin/bulk-commitments"
                >
                    View bulk commitment management
                </Button>
            )}
        >
            <div className={styles.filters}>
                <TextField
                    select
                    variant="standard"
                    label="Customer"
                    value={selectedCustomerId}
                    onChange={(event) => setSelectedCustomerId(event.target.value)}
                >
                    <MenuItem value="ALL">
                        -- All --
                    </MenuItem>

                    <MenuItem value="1">
                        Customer one
                    </MenuItem>

                    <MenuItem value="2">
                        Customer two
                    </MenuItem>

                    <MenuItem value="3">
                        Customer three
                    </MenuItem>
                </TextField>

                <FilterTextField
                    autoFocus
                    placeholder="Filter commitments"
                    helperText="Filter by commitment id"
                    value={filterText}
                    onChange={(event) => setFilterText(event.target.value)}
                />

                <FormControlLabel
                    className={styles.showInactive}
                    control={<Checkbox onChange={() => setShowPastDeliveries(!showPastDeliveries)} />}
                    label="Show past deliveries"
                />
            </div>

            {commitmentsByCustomerId && (
                <div className={styles.sections}>
                    {Object.entries(commitmentsByCustomerId).map(([ thisCustomerId, commitments ]) => (
                        <ExpandableHeader
                            key={selectedCustomerId + thisCustomerId}
                            defaultExpand={selectedCustomerId.toString() === thisCustomerId}
                            disableExpand={false}
                            title={customerNameMap[thisCustomerId]}
                            secondaryText={`${commitments.length} commitment${commitments.length === 1 ? '' : 's'}`}
                            expandedContent={(
                                <div className={styles.commitmentContainer}>
                                    {commitments.map((commitment) => (
                                        <CommitmentTrackingCard
                                            key={commitment.id}
                                            commitment={commitment as BulkCommitmentDetails}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    ))}
                </div>
            )}

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    add: BulkCommitmentDialog,
    ':commitmentID': BulkCommitmentDialog
};

export const customerNameMap: { [key: string]: string } = {
    1: 'Customer one',
    2: 'Customer two',
    3: 'Customer three'
};

export const productNameMap: { [key: string]: string } = {
    1: 'Product one',
    2: 'Product two'
};

interface CommitmentCardProps {
    commitment: BulkCommitmentDetails;
    hideTiersButton?: boolean;
    onAddFiles?: (files: FileList, commitmentId: string) => Promise<void>;
    loading?: boolean;
    toPathPrefix?: string;
}

export function CommitmentTrackingCard({
    commitment, hideTiersButton, onAddFiles, loading, toPathPrefix
}: CommitmentCardProps) {
    const { pricingTiers } = commitment;
    const currentDelivered = pricingTiers
        .flatMap(({ loans }) => loans)
        .reduce((total, loan) => total + loan.loanAmount, 0);

    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const { primary: { main: primaryColor } } = useTheme().palette;
    const colors: tinycolor.Instance[] | null = useMemo(() => (
        getGradient(primaryColor, 3)
    ), [ primaryColor ]);

    const [ tierExpansionState, setTierExpansionState ] = useState<{ [key: string]: boolean }>(Object.fromEntries(
        pricingTiers.map(({ productId, noteRate, price }) => (
            [ `${productId}${noteRate}${price}`, false ]
        ))
    ));

    const dropContainerRef = useRef<HTMLDivElement | null>(null);
    const [ inDropZone, setInDropZone ] = useState(false);

    function handleDragEvent(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === 'dragover' || event.type === 'dragenter') {
            event.dataTransfer.dropEffect = 'copy';
            setInDropZone(true);
        } else if (event.type === 'drop') {
            onAddFiles?.(event.dataTransfer.files, commitment.id);
            setInDropZone(false);
        } else if (event.target === dropContainerRef.current) {
            setInDropZone(false);
        }
    }

    const [ debouncedDelivered ] = useDebounce(currentDelivered, 5000);
    // Used for the transition so the value doesn't change as it disappears
    const [ transitionDebouncedDelivered ] = useDebounce(currentDelivered, 5300);

    return (
        <Paper
            variant="outlined"
            {...(onAddFiles && {
                ref: dropContainerRef,
                onDrop: handleDragEvent,
                onDragOver: handleDragEvent,
                onDragEnter: handleDragEvent,
                onDragLeave: handleDragEvent
            })}
            className={clsx(styles.card, {
                [styles.expired]: isBefore(parseISO(commitment.deliveryExpiration), new Date()),
                [styles.inDropZone]: (inDropZone || loading)
            })}
        >
            <div className={styles.header}>
                <MuiLink
                    component={Link}
                    to={toPathPrefix ? `${toPathPrefix}/${commitment.id}` : `${commitment.id}`}
                >
                    {commitment.id}
                </MuiLink>

                <div className={styles.chips}>
                    <Tooltip
                        title={(
                            <>
                                <div>Trade amount</div>

                                <div>{formatCurrency(commitment.tradeAmount)}</div>
                            </>
                        )}
                    >
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {formatCurrencyAbbreviation(commitment.tradeAmount)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade incentive">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {commitment.tradeIncentive.toFixed(5)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade variance">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {commitment.tradeVariance.toFixed(2)}%
                        </Typography>
                    </Tooltip>
                </div>

                <div className={styles.datesRow}>
                    <IconTypography
                        fontWeight={400}
                        variant="body2"
                        icon={(
                            <Tooltip title="Trade lock date">
                                <Lock
                                    color="primary"
                                    fontSize="small"
                                />
                            </Tooltip>
                        )}
                    >
                        {formatDistanceToNowStrict(parseISO(commitment.lockDate), {
                            addSuffix: true
                        })}
                    </IconTypography>

                    <IconTypography
                        fontWeight={400}
                        variant="body2"
                        icon={(
                            <Tooltip title="Delivery expiration">
                                {isBefore(parseISO(commitment.deliveryExpiration), new Date()) ? (
                                    <HourglassBottom
                                        color="primary"
                                        fontSize="small"
                                    />
                                ) : (
                                    <HourglassTop
                                        color="primary"
                                        fontSize="small"
                                    />
                                )}
                            </Tooltip>
                        )}
                    >
                        {formatDistanceToNowStrict(parseISO(commitment.deliveryExpiration), {
                            addSuffix: true
                        })}
                    </IconTypography>

                    {!hideTiersButton && (
                        <Button
                            size="small"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                            View ({commitment.pricingTiers.length}) pricing

                            tier{commitment.pricingTiers.length === 1 ? '' : 's'}
                        </Button>
                    )}
                </div>

                <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(undefined)}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                    }}
                    classes={{
                        paper: styles.popoverPaper
                    }}
                >
                    {commitment.pricingTiers.map((tier) => {
                        const key = `${tier.productId}${tier.noteRate}${tier.price}`;
                        const isExpanded = tierExpansionState?.[key];

                        return (
                            <PricingTierTrackingCard
                                key={key}
                                tier={tier}
                                isExpanded={isExpanded}
                                onExpandClick={() => setTierExpansionState({
                                    ...tierExpansionState,
                                    [key]: !isExpanded
                                })}
                                colors={colors}
                            />
                        );
                    })}
                </Popover>
            </div>

            <CardLinearProgress
                leftLabel={(
                    <div className={styles.updatedAmountOuter}>
                        <span>
                            {((currentDelivered / commitment.tradeAmount) * 100).toFixed(2)}% delivered
                        </span>

                        <div className={styles.updatedAmountContainer}>
                            <Grow in={currentDelivered !== debouncedDelivered}>
                                <IconTypography
                                    fontWeight={400}
                                    color="inherit"
                                    variant="body2"
                                    compact
                                    className={styles.updatedAmountTypography}
                                    icon={(
                                        <ArrowDropUp
                                            color="success"
                                            fontSize="small"
                                        />
                                    )}
                                >
                                    {(((currentDelivered - transitionDebouncedDelivered)
                                        / commitment.tradeAmount) * 100).toFixed(2)}%
                                </IconTypography>
                            </Grow>
                        </div>
                    </div>
                )}
                centerLabel={(
                    <div className={styles.deliveryCompleteContainer}>
                        <Grow in={currentDelivered.toFixed(2) === commitment.tradeAmount.toFixed(2)}>
                            <IconTypography
                                fontWeight={400}
                                className={styles.deliveryCompleteTypography}
                                icon={(
                                    <CheckCircleOutline
                                        color="success"
                                        fontSize="small"
                                    />
                                )}
                            >
                                Delivery complete
                            </IconTypography>
                        </Grow>
                    </div>
                )}
                rightLabel={(
                    <div className={styles.updatedAmountOuter}>
                        <div className={styles.updatedAmountContainer}>
                            <Grow in={currentDelivered !== debouncedDelivered}>
                                <IconTypography
                                    fontWeight={400}
                                    color="inherit"
                                    variant="body2"
                                    compact
                                    className={styles.updatedAmountTypography}
                                    icon={(
                                        <ArrowDropUp
                                            color="success"
                                            fontSize="small"
                                        />
                                    )}
                                >
                                    {formatCurrencyAbbreviation(currentDelivered - transitionDebouncedDelivered)}
                                </IconTypography>
                            </Grow>
                        </div>

                        <span>
                            {formatCurrencyAbbreviation(currentDelivered)} /

                            {' '}{formatCurrencyAbbreviation(commitment.tradeAmount)}
                        </span>
                    </div>
                )}
                value={(currentDelivered / commitment.tradeAmount) * 100}
            />

            {(inDropZone || loading) && (
                <div className={styles.dropMask}>
                    {loading ? (
                        <CircularProgress
                            color="primary"
                            size={24}
                        />
                    ) : (
                        <Upload
                            color="secondary"
                            fontSize="large"
                        />
                    )}

                    {loading ? (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            Delivering loans...
                        </Typography>
                    ) : (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            Drop here to deliver to Commitment #

                            <Typography
                                variant="inherit"
                                color="primary"
                                component="span"
                            >
                                {commitment.id}
                            </Typography>
                        </Typography>
                    )}
                </div>
            )}
        </Paper>
    );
}

interface CardLinearProgressProps {
    leftLabel?: ReactNode;
    centerLabel?: ReactNode;
    rightLabel: ReactNode;
    value: number;
    disabled?: boolean;
    expanded?: boolean;
    onExpandClick?: () => void;
    linearProgressClassName?: string;
    typographyVariant?: TypographyVariant;
}

export function CardLinearProgress({
    leftLabel, centerLabel, rightLabel, value, disabled, expanded, onExpandClick,
    linearProgressClassName, typographyVariant = 'body2'
}: CardLinearProgressProps) {
    return (
        <div>
            <div
                className={clsx(styles.progressLabel, {
                    [styles.withExpandIcon]: onExpandClick
                })}
            >
                {onExpandClick && (
                    <IconButton
                        size="small"
                        className={styles.linearProgressIconButton}
                        onClick={onExpandClick}
                    >
                        {expanded ? (
                            <ExpandLess
                                fontSize="small"
                                color="secondary"
                            />
                        ) : (
                            <ExpandMore
                                fontSize="small"
                                color="secondary"
                            />
                        )}
                    </IconButton>
                )}

                <Typography variant={typographyVariant}>
                    {leftLabel}
                </Typography>

                <Typography variant={typographyVariant}>
                    {centerLabel}
                </Typography>

                <Typography variant={typographyVariant}>
                    {rightLabel}
                </Typography>
            </div>

            <LinearProgress
                className={clsx(styles.linearProgress, linearProgressClassName, {
                    [styles.disabled]: disabled
                })}
                variant="determinate"
                color={disabled ? 'inherit' : 'primary'}
                value={value}
            />
        </div>
    );
}
