import {
    EligibilityVersion, Investor, InvestorStatus, PermissionType
} from '@api';
import {
    Button, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import { usePageMessage } from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks';
import StatusActionsCard from '@views/admin/components/StatusActionsCard';
import StatusChecklistItem from '@views/admin/customers/components/StatusChecklistItem';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import { EligibilityVersionButton } from '@views/admin/investors/components/EligibilityVersionButton';
import Page from '@views/components/Page';
import { ReactNode, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './InvestorDetailPageBase.module.scss';
import InvestorDetailSummaryCard from './components/InvestorDetailsSummaryCard';


const { ACTIVE, INACTIVE, PENDING } = InvestorStatus;

interface InvestorDetailPageBaseProps {
    children: ReactNode;
    investor: Investor | undefined;
    setInvestor(investor: Investor): void;
    loading?: boolean;
    onUpdateStatus: ((newStatus: InvestorStatus) => Promise<Investor> | undefined) | undefined;
    statusCardChildren?: ReactNode;
    firstBreadcrumb: ReactNode;
    eligibilityVersions: EligibilityVersion[] | undefined;
    isSelectedVersionEditable: boolean;
    editPermission: PermissionType;
}

export function InvestorDetailPageBase({
    children,
    investor,
    setInvestor,
    loading,
    onUpdateStatus,
    statusCardChildren,
    firstBreadcrumb,
    eligibilityVersions,
    isSelectedVersionEditable,
    editPermission
}: InvestorDetailPageBaseProps) {
    const pageMessage = usePageMessage();
    const { search } = useLocation();

    const { loanPrograms } = useContext(LoanProgramsContext);
    const [ statusLoading, setStatusLoading ] = useState(false);

    async function updateStatus() {
        if (investor && onUpdateStatus) {
            try {
                setStatusLoading(true);
                setInvestor({
                    ...investor,
                    ...(await onUpdateStatus(investor.status === ACTIVE ? INACTIVE : ACTIVE))
                });

                pageMessage.success('Investor status updated');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while updating the investor status', error);
            }

            setStatusLoading(false);
        }
    }

    const [ canEdit ] = useHasPermission([ editPermission ]);

    return (
        <Page
            header="Investor Details"
            loading={loading}
            headerActions={(
                <EligibilityVersionButton eligibilityVersions={eligibilityVersions} />
            )}
            breadcrumbPathLengths={[ 2, 1 ]}
            breadcrumbs={[
                firstBreadcrumb,
                investor?.name
            ]}
        >
            {!investor && !loading ? (
                <Typography>
                    The investor you tried to view does not exist.{' '}

                    <MuiLink
                        component={Link}
                        to={`./..${search}`}
                    >
                        View all investors
                    </MuiLink>.
                </Typography>
            ) : investor && (
                <div className={styles.root}>
                    <InvestorDetailSummaryCard
                        investor={investor}
                        canEdit={canEdit}
                    />

                    <StatusActionsCard
                        noBorder={false}
                        variant={investor.status}
                        isLoading={statusLoading}
                        actions={(
                            <Tooltip
                                title={statusLoading ? 'Loading...' : !canEdit
                                    ? 'You don\'t have permission to manage investors'
                                    : !isSelectedVersionEditable
                                        ? 'You can only change investor status for a future eligibility version'
                                        : investor.status === PENDING
                                            ? 'Complete required items prior to approval' : ''}
                            >
                                <span>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={updateStatus}
                                        disabled={statusLoading || !isSelectedVersionEditable || !canEdit}
                                    >
                                        {investor.status === ACTIVE ? (
                                            'Deactivate'
                                        ) : investor.status === INACTIVE ? (
                                            'Reactivate'
                                        ) : 'Activate'}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                        checklistItems={investor.status === PENDING && (
                            <StatusChecklistItem
                                label="Loan programs"
                                done={!!loanPrograms?.length}
                            />
                        )}
                        status={{
                            [ACTIVE]: 'Active',
                            [INACTIVE]: 'Inactive',
                            [PENDING]: 'Pending approval'
                        }[investor.status]}
                    >
                        {statusCardChildren}
                    </StatusActionsCard>

                    {children}
                </div>
            )}
        </Page>
    );
}
