import { CustomerDetails as Customer, PermissionType } from '@api';
import { Button, Tooltip, Typography } from '@mui/material';
import { AddressTypography, LabeledValue } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import EditableSectionCard from '../../../components/EditableSectionCard';
import CustomerDetails from '../../components/CustomerDetails';
import { CustomerDetailContext } from '../CustomerDetailPage';

import styles from './CustomerDetailsSummaryCard.module.scss';


interface CustomerDetailsSummaryCardProps {
    customer?: Customer;
    showAdditionalDBAs?: boolean;
    readOnly?: boolean;
    disableEdit?: boolean;
    disabledTooltip?: string;
}

export default function CustomerDetailsSummaryCard({
    customer, showAdditionalDBAs, readOnly, disableEdit, disabledTooltip
}: CustomerDetailsSummaryCardProps) {
    const mainCustomer = useContext(CustomerDetailContext).customer!;
    if (!customer) {
        customer = mainCustomer;
    }

    const [ canViewBranches, canAddBranches ] = useHasPermission(
        [ PermissionType.VIEW_CUSTOMER_BRANCHES, PermissionType.CREATE_CUSTOMER_BRANCHES ]
    );

    return (
        <EditableSectionCard
            header={customer.name}
            editTo="edit"
            readOnly={readOnly}
            disableEdit={disableEdit}
            disabledTooltip={disabledTooltip}
        >
            <div className={styles.content}>
                <CustomerDetails
                    dba={customer.name !== customer?.dbaNames?.[0]
                        ? customer?.dbaNames?.[0]
                        : undefined}
                    nmlsID={customer.nmlsId}
                    taxID={customer.taxId}
                    variant="body1"
                />

                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                    >
                        Address
                    </Typography>

                    <AddressTypography address={customer.address} />
                </div>

            </div>

            {!readOnly && (
                <div className={styles.buttons}>
                    <Tooltip title={canViewBranches ? '' : 'You don\'t have permission to view branches'}>
                        <span>
                            <Button
                                component={Link}
                                to="branches"
                                disabled={!canViewBranches}
                            >
                                View branches ({customer.branches?.length || '0'})
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title={canAddBranches ? '' : 'You don\'t have permission to add branches'}>
                        <span>
                            <Button
                                component={Link}
                                to="branches/add"
                                disabled={!canAddBranches}
                            >
                                Add branch
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            )}

            {showAdditionalDBAs && (
                <LabeledValue
                    label="Other DBA names:"
                    value={customer.dbaNames?.map((dba, index) => (
                        <Fragment key={dba}>
                            {dba}

                            {index !== (customer?.dbaNames?.length || 0) - 1 && <br />}
                        </Fragment>
                    )) || 'None'}
                    variants={{ value: 'body1' }}
                />
            )}
        </EditableSectionCard>
    );
}
