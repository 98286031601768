export enum SpecialtyProgram {
    HOME_POSSIBLE = 'HOME_POSSIBLE',
    HOME_READY = 'HOME_READY',
    HOME_ONE = 'HOME_ONE',
    HOME_STYLE = 'HOME_STYLE',
    REFI_NOW = 'REFI_NOW',
    REFI_POSSIBLE = 'REFI_POSSIBLE',
    VA_IRRRL = 'VA_IRRRL',
    CONSTRUCTION_TO_PERMANENT = 'CONSTRUCTION_TO_PERMANENT'
}

export const specialtyProgramDisplay = {
    [SpecialtyProgram.HOME_POSSIBLE]: 'Home possible',
    [SpecialtyProgram.HOME_READY]: 'Home ready',
    [SpecialtyProgram.HOME_ONE]: 'Home one',
    [SpecialtyProgram.HOME_STYLE]: 'Home style',
    [SpecialtyProgram.REFI_NOW]: 'Refi now',
    [SpecialtyProgram.REFI_POSSIBLE]: 'Refi possible',
    [SpecialtyProgram.VA_IRRRL]: 'VA IRRRL',
    [SpecialtyProgram.CONSTRUCTION_TO_PERMANENT]: 'Construction-to-Permanent'
};
