import {
    NumericFieldOperator, NumericMatrixEntry, NumericMatrixEntryType, numericFieldOperatorDisplay
} from '@api';


export const numericMatrixEntryValidationRules = {
    pattern: {
        value: /^[<>]\s*\d+\.*\d*|^\d+\.*\d*\s*-\s*\d+\.*\d*|^\d+\.*\d*$/g,
        message: 'Enter a valid numeric range. e.g. 15, < 15, > 15, or 15 - 25'
    }
};

const numericMatrixEntryRegex = /^([<>])\s*(\d+\.*\d*)|^(\d+\.*\d*)\s*-\s*(\d+\.*\d*)|^(\d+\.*\d*)$/;
export function parseNumericMatrixEntry(entryStr: string | undefined): NumericMatrixEntry | undefined {
    if (!entryStr) {
        return undefined;
    }

    const match = entryStr.match(numericMatrixEntryRegex);

    if (!match) {
        throw new Error('Encountered an incorrectly formatted numeric range');
    }

    const [ // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _, operator, operatorValue, min, max, value
    ] = match;

    return min ? {
        type: NumericMatrixEntryType.RANGE,
        min: parseFloat(min),
        max: parseFloat(max)
    } : {
        type: NumericMatrixEntryType.COMPARISON,
        operator: value
            ? NumericFieldOperator.EQUALS
            : operator === '<'
                ? NumericFieldOperator.LESS_THAN
                : NumericFieldOperator.GREATER_THAN,
        value: parseFloat(value || operatorValue || '0')
    };
}


export function numericEntryToString(entry: NumericMatrixEntry | undefined): string | undefined {
    return !entry ? undefined : formatNumericMatrixEntry(entry);
}

export function formatNumericMatrixEntry(
    entry: NumericMatrixEntry,
    formatValue: (value: number | undefined) => string | null = (value) => value?.toString() || null
) {
    return entry.type === NumericMatrixEntryType.RANGE
        ? `${formatValue(entry.min)} - ${formatValue(entry.max)}`
        : `${numericFieldOperatorDisplay[entry.operator]} ${formatValue(entry.value)}`;
}
