import api, {
    CustomerDetails, PermissionType, Product, User
} from '@api';
import { Button } from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { withAuth } from '@utils/withAuth';
import DueDiligenceChecklistDialog
    from '@views/admin/customers/CustomerDetailPage/components/dialogs/DueDiligenceChecklistDialog';
import LimitsDialog from '@views/admin/customers/CustomerDetailPage/components/dialogs/LimitsDialog';
import { CustomerManagementContext } from '@views/admin/customers/CustomerManagementPage';
import { LockAvailabilityDialog } from '@views/admin/lockAvailability/LockAvailabilityDialog';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import Page from '../../../components/Page';
import { AdminRouteParams } from '../../components/AdminPageTemplate';
import DetailPageViewCard from '../../components/DetailPageViewCard';

import styles from './CustomerDetailPage.module.scss';
import CustomerActionsCard from './components/CustomerActionsCard';
import CustomerDetailsSummaryCard from './components/CustomerDetailsSummaryCard';
import CustomerPrimaryContactCard from './components/CustomerPrimaryContactCard';
import CustomerProductsCard from './components/CustomerProductsCard';
import BranchDialog from './components/dialogs/BranchDialog';
import CompensationDialog from './components/dialogs/CompensationDialog';
import EditCustomerDetailsDialog from './components/dialogs/EditCustomerDetailsDialog';
import EditCustomerPrimaryContactDialog from './components/dialogs/EditCustomerPrimaryContactDialog';
import EligibleProductsDialog from './components/dialogs/EligibleProductsDialog';
import HistoryDialog from './components/dialogs/HistoryDialog';
import LicenseDialog from './components/dialogs/LicenseDialog';
import UsersAndContactsDialog from './components/dialogs/UsersAndContactsDialog';
import WireInstructionsDialog from './components/dialogs/WireInstructionsDialog';


export interface CustomerDetailContextValue {
    customer?: CustomerDetails;
    updateCustomer: Dispatch<SetStateAction<CustomerDetails | undefined>>;
    refreshCustomer: () => Promise<void>;
    users: User[];
    products: Product[] | undefined;
}

export const CustomerDetailContext = createContext<CustomerDetailContextValue>({
    customer: undefined,
    updateCustomer: () => {},
    refreshCustomer: async () => {},
    users: [],
    products: undefined
});

export default function CustomerDetailPage() {
    const pageMessage = usePageMessage();
    const { id: clientID } = useGetCurrentAccount();

    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const { setEntities: setCustomers } = useContext(CustomerManagementContext);

    const [ customer, setCustomer ] = useState<CustomerDetails>();
    const [ products, setProducts ] = useState<Product[]>();
    const [ users, setUsers ] = useState<User[]>([]);

    useEffect(() => {
        if (customer) {
            setCustomers((customers) => replaceItemById(customers, customer));
        }
    }, [ customer, setCustomers ]);

    const customerContextValue = useMemo(() => ({
        customer,
        updateCustomer: setCustomer,
        refreshCustomer: async () => {
            try {
                setCustomer(await api.customer.getCustomer(clientID, customerID));
            } catch (error) {
                pageMessage.handleApiError('An error occured while refreshing to reflect new customer change(s)', error);
            }
        },
        users,
        products
    }), [
        customer, users, products, clientID, customerID, pageMessage
    ]);

    useAsyncEffect(useCallback(async () => {
        try {
            const [
                customer, products, users
            ] = await Promise.all([
                api.customer.getCustomer(clientID, customerID),
                api.products.getAllProducts(clientID),
                api.users.getUsers(clientID, customerID)
            ]);

            setCustomer(customer);
            setProducts(products);
            setUsers(users);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the customer details', error);
        }
    }, [
        clientID, customerID, pageMessage
    ]));

    const [ canEditCustomer ] = useHasPermission([ PermissionType.EDIT_CUSTOMERS ]);

    return (
        <Page
            header="Customer details"
            loading={!customer}
            headerActions={(
                <Button
                    component={Link}
                    to="history"
                >
                    View history
                </Button>
            )}
            breadcrumbs={[
                'Customers',
                customer?.name
            ]}
        >
            {customer && (
                <CustomerDetailContext.Provider value={customerContextValue}>
                    <div className={styles.root}>
                        <div>
                            <CustomerDetailsSummaryCard
                                disableEdit={!canEditCustomer}
                                disabledTooltip="You don't have permission to edit the customer details"
                            />

                            <CustomerProductsCard
                                customer={customer}
                                products={products}
                            />
                        </div>

                        <div>
                            <DetailPageViewCard
                                label="Compensation & fees"
                                to="compensation"
                                permission={PermissionType.VIEW_FEE_COMPENSATION}
                                noPermissionContent="You do not have permission to view compensation & fees"
                            />

                            <DetailPageViewCard
                                label="Due-diligence"
                                to="due-diligence"
                                permission={PermissionType.VIEW_FEE_COMPENSATION}
                                noPermissionContent="You do not have permission to view compensation & fees"
                            />

                            <DetailPageViewCard
                                label="State licenses"
                                to="licenses"
                            />

                            <DetailPageViewCard
                                label="Users & contacts"
                                to="users"
                                permission={PermissionType.VIEW_USERS || PermissionType.VIEW_CUSTOMER_CONTACTS}
                                noPermissionContent="You do not have permission to view users & contacts"
                            />

                            <DetailPageViewCard
                                label="Wire instructions"
                                to="wire-instructions"
                                permission={PermissionType.VIEW_WIRE_INFO}
                                noPermissionContent="You do not have permission to view wire instructions"
                            />

                            <DetailPageViewCard
                                label="Lock availability"
                                to="lock-availability"
                                permission={PermissionType.VIEW_LOCK_AVAILABILITY}
                                noPermissionContent="You do not have permission to view lock availability"
                            />

                            <DetailPageViewCard
                                label="Allocation limits"
                                to="limits"
                                permission={PermissionType.VIEW_LOAN_ALLOCATION_LIMITS}
                                noPermissionContent="You do not have permission to view allocation limits"
                            />
                        </div>

                        <div>
                            <CustomerActionsCard />

                            <CustomerPrimaryContactCard primaryContact={customer.primaryContact} />
                        </div>
                    </div>

                    <RoutedDialogManager routes={dialogRoutes} />
                </CustomerDetailContext.Provider>
            )}
        </Page>
    );
}

// TODO add permissions to dialogs
const dialogRoutes = {
    'branches/*': BranchDialog,
    edit: EditCustomerDetailsDialog,
    'due-diligence': withAuth(DueDiligenceChecklistDialog, [ PermissionType.VIEW_DUE_DILIGENCE_STEP ], true),
    compensation: withAuth(CompensationDialog, [ PermissionType.VIEW_FEE_COMPENSATION ], true),
    history: HistoryDialog,
    licenses: withAuth(LicenseDialog, [ PermissionType.VIEW_LICENSES ], true),
    'lock-availability/:day/edit': LockAvailabilityDialog,
    'lock-availability/:id/edit-override': LockAvailabilityDialog,
    'lock-availability/*': LockAvailabilityDialog,
    products: withAuth(
        EligibleProductsDialog, [ PermissionType.MANAGE_ELIGIBLE_PRODUCTS ], true
    ),
    users: withAuth(UsersAndContactsDialog, [ PermissionType.VIEW_CUSTOMER_CONTACTS, PermissionType.VIEW_USERS ], true),
    'wire-instructions': WireInstructionsDialog,
    'edit-primary-contact': withAuth(EditCustomerPrimaryContactDialog, [ PermissionType.MANAGE_CUSTOMER_CONTACTS ], true),
    limits: withAuth(
        LimitsDialog,
        [
            PermissionType.VIEW_LOAN_ALLOCATION_LIMITS,
            PermissionType.MANAGE_LOAN_ALLOCATION_LIMITS
        ],
        true
    )
};
