import api, {
    ActiveInactiveEnum, License, licenseTypeDisplay, stateDisplay
} from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, RoutedDialogProps, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AddEditLicenseDialogContent.module.scss';


export interface AddEditLicenseDialogContentProps extends Omit<RoutedDialogProps, 'title' | 'children'> {
    defaultValues?: License;
    onAdd: (license: License) => void;
    onEdit: (license: License) => void;
    onDone: () => void;
}

export default function AddEditLicenseDialogContent({
    defaultValues, onAdd, onEdit, onDone
}: AddEditLicenseDialogContentProps) {
    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const { id: clientID } = useGetCurrentAccount();

    const formMethods = useForm({ defaultValues });
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            if (defaultValues) {
                onEdit(await api.customer.licenses.editLicense(clientID, customerID, formData));
            } else {
                formData.status = ActiveInactiveEnum.ACTIVE;
                onAdd(await api.customer.licenses.addLicense(clientID, customerID, formData));
            }

            pageMessage.success('License saved');

            onDone();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the license', error);
        }

        setLoading(false);
    });

    return (
        <>
            <DialogContent>
                <form
                    noValidate
                    id="add-edit-license-form"
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.formContent}>
                            <TextField<License>
                                name="licenseNumber"
                                label="License Number"
                                required
                            />

                            <TextField<License>
                                name="type"
                                label="License Type"
                                select
                                required
                            >
                                {renderEnumOptions(licenseTypeDisplay)}
                            </TextField>

                            <TextField<License>
                                name="state"
                                label="State"
                                select
                                required
                            >
                                {renderEnumOptions(stateDisplay)}
                            </TextField>

                            <TextField<License>
                                name="renewedThroughYear"
                                label="Valid Through"
                                type="number"
                                rules={{
                                    pattern: {
                                        value: /^20[0-9]{2}$/,
                                        message: 'Not a valid year'
                                    }
                                }}
                                required
                            />
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onDone}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form="add-edit-license-form"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
