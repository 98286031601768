import api, { ExclusionOverlay, ExclusionOverlayType } from '@api';
import { Paper, Typography } from '@mui/material';
import {
    Autocomplete, Switch, TextField
} from '@tsp-ui/core/components';
import {
    Override, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import {
    EligibilityExclusionsFormValues,
    ExclusionGroupRow,
    exclusionsToFormValues,
    formValuesToExclusions,
    getEmptyEligibilityExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExclusionOverlayContext } from '../InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ExclusionOverlayForm.module.scss';


interface ExclusionOverlayFormProps {
    activeStep: number;
    lastStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export interface ExclusionOverlayFormValues extends Override<ExclusionOverlay, {
    exclusions: EligibilityExclusionsFormValues
}> {
    allInvestors: boolean;
    allPrograms: boolean;
}

export default function ExclusionOverlayForm({
    activeStep, lastStep, setActiveStep, setLoading
} : ExclusionOverlayFormProps) {
    const { overlayID, investorID } = useParams();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const clientId = useActingClientID();
    const { search } = useLocation();

    const { overlays, setOverlays } = useContext(ExclusionOverlayContext);
    const { investors } = useContext(InvestorEligibilityContext);
    const { loanPrograms } = useContext(LoanProgramsContext);
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const type = useOverlayType();
    const overlay = overlays.find(overlay => overlay.id === overlayID);

    const formMethods = useForm<ExclusionOverlayFormValues>({
        defaultValues: exclusionOverlayToFormValues(overlay) || {
            type,
            investorIds: type !== ExclusionOverlayType.CLIENT ? [ investorID! ] : [],
            loanProgramIds: type === ExclusionOverlayType.CLIENT ? [ 'ALL' ] : [],
            exclusions: getEmptyEligibilityExclusions()
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        if (activeStep < lastStep) {
            setActiveStep(activeStep + 1);
            return;
        }

        setLoading(true);

        try {
            const updatedValuesToSubmit = formValuesToExclusionOverlay(formValues, eligibilityVersionId!);

            setOverlays(overlayID
                ? replaceItemById(
                    overlays, await api.overlay.updateExclusionOverlay(clientId, updatedValuesToSubmit)
                )
                : [ ...overlays, await api.overlay.createExclusionOverlay(clientId, updatedValuesToSubmit) ]);

            navigate(`..${search}`);

            pageMessage.success(`Exclusion overlay ${overlay ? 'saved' : 'added'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the exclusion overlay', error);
        }

        setLoading(false);
    });

    const applyToAllInvestors = formMethods.watch('allInvestors');
    const applyToAllPrograms = formMethods.watch('allPrograms');

    const exclusionElements = (
        <>
            <Typography
                align="center"
            >
                What is your exclusion criteria?

                {type === ExclusionOverlayType.LOAN_PROGRAM && (
                    <Typography
                        align="center"
                        variant="caption"
                        component="p"
                        color="textSecondary"
                    >
                        This overlay will apply only to this loan program
                    </Typography>
                )}
            </Typography>

            <Paper
                className={styles.exclusions}
                variant="outlined"
            >
                <ExclusionGroupRow nameBase="exclusions" />
            </Paper>
        </>
    );

    return (
        <form
            id={ExclusionOverlayForm.formID}
            className={clsx(styles.root, {
                [styles.targetStep]: type !== ExclusionOverlayType.LOAN_PROGRAM && activeStep === 1,
                [styles.exclusionStep]: activeStep === 2 || (
                    type === ExclusionOverlayType.LOAN_PROGRAM && activeStep === 1
                )
            })}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                {activeStep === 0 ? (
                    <>
                        <TextField<ExclusionOverlayFormValues>
                            name="name"
                            label="Overlay name"
                            required
                        />

                        <TextField<ExclusionOverlayFormValues>
                            name="description"
                            label="Description"
                            required
                            multiline
                            rows={2}
                        />
                    </>
                ) : activeStep === 1 ? (
                    type === ExclusionOverlayType.CLIENT ? (
                        <>
                            <Typography
                                align="center"
                            >
                                Which investors should this overlay should apply to?

                                <Typography
                                    align="center"
                                    variant="caption"
                                    component="p"
                                    color="textSecondary"
                                >
                                    This overlay will apply to all loan programs from the selected investors
                                </Typography>
                            </Typography>

                            <Autocomplete<ExclusionOverlayFormValues>
                                key="investors"
                                name="investorIds"
                                label="Investors"
                                multiple
                                required
                                options={investors!.map(investor => investor.id)}
                                getOptionLabel={option => investors!.find(investor => investor.id === option)?.name
                                    || 'All'}
                                className={styles.autocomplete}
                                disabled={applyToAllInvestors}
                            />

                            <Switch<ExclusionOverlayFormValues>
                                label="Apply to all investors"
                                name="allInvestors"
                                onChange={(_, checked) => {
                                    formMethods.setValue('investorIds', checked ? [ 'ALL' ] : [], {
                                        shouldValidate: true
                                    });
                                }}
                            />
                        </>
                    ) : type === ExclusionOverlayType.INVESTOR ? (
                        <>
                            <Typography
                                align="center"
                            >
                                Which loan programs should this overlay should apply to?
                            </Typography>

                            <Autocomplete<ExclusionOverlayFormValues>
                                key="programs"
                                name="loanProgramIds"
                                label="Loan Programs"
                                multiple
                                required
                                options={loanPrograms!.map(loanProgram => loanProgram.id)}
                                getOptionLabel={option => loanPrograms!.find(
                                    loanProgram => loanProgram.id === option
                                )?.name || 'All'}
                                className={styles.autocomplete}
                                disabled={applyToAllPrograms}
                            />

                            <Switch<ExclusionOverlayFormValues>
                                label="Apply to all loan programs"
                                name="allPrograms"
                                onChange={(_, checked) => {
                                    formMethods.setValue('loanProgramIds', checked ? [ 'ALL' ] : [], {
                                        shouldValidate: true
                                    });
                                }}
                            />
                        </>
                    ) : exclusionElements
                ) : exclusionElements}
            </FormProvider>
        </form>
    );
}

ExclusionOverlayForm.formID = 'edit-overlay-form';

export function useOverlayType(): ExclusionOverlayType {
    const { overlayID } = useParams();
    const { pathname } = useLocation();

    const { overlays } = useContext(ExclusionOverlayContext);
    const overlay = overlays.find(overlay => overlay.id === overlayID);

    let type: ExclusionOverlayType;

    if (!overlay) {
        type = pathname.includes('loan-programs')
            ? ExclusionOverlayType.LOAN_PROGRAM
            : pathname.includes('investors')
                ? ExclusionOverlayType.INVESTOR
                : ExclusionOverlayType.CLIENT;
    } else {
        type = overlay.type; // eslint-disable-line prefer-destructuring
    }

    return type;
}

function exclusionOverlayToFormValues(overlay?: ExclusionOverlay): ExclusionOverlayFormValues | undefined {
    return overlay ? {
        ...overlay,
        allInvestors: overlay.investorIds?.includes('ALL') || false,
        allPrograms: overlay.loanProgramIds?.includes('ALL') || false,
        exclusions: exclusionsToFormValues(overlay.exclusions)
    } : undefined;
}

function formValuesToExclusionOverlay(
    formValues: ExclusionOverlayFormValues, eligibilityVersionId: string
): ExclusionOverlay {
    return {
        ...formValues,
        exclusions: formValuesToExclusions(formValues.exclusions, eligibilityVersionId)
    };
}
