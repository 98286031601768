import { AgencyApproval, agencyDisplay } from '@api';
import { DateField, TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AgencyApprovalForm.module.scss';


interface AgencyApprovalFormProps {
    defaultValues?: AgencyApproval;
    handleSubmit(agencyApproval: AgencyApproval): void;
}

export function AgencyApprovalForm({ defaultValues, handleSubmit: handleSubmitProp }: AgencyApprovalFormProps) {
    const formMethods = useForm<AgencyApproval>({ defaultValues });
    const handleSubmit = formMethods.handleSubmit(handleSubmitProp);

    const firstApprovalDateString = formMethods.watch('firstApprovalDate');
    const firstApprovalDate = firstApprovalDateString ? new Date(firstApprovalDateString) : undefined;
    const latestApprovalDateString = formMethods.watch('latestApprovalDate');
    const latestApprovalDate = latestApprovalDateString ? new Date(latestApprovalDateString) : undefined;

    return (
        <form
            noValidate
            id={`agency-approval-${defaultValues?.id || 'new'}`}
            onSubmit={handleSubmit}
            className={styles.formContent}
        >
            <FormProvider {...formMethods}>
                <TextField<AgencyApproval>
                    name="agency"
                    label="Agency"
                    size="small"
                    variant="outlined"
                    required
                    select
                >
                    {renderEnumOptions(agencyDisplay)}
                </TextField>

                <div />

                <DateField<AgencyApproval>
                    name="firstApprovalDate"
                    label="First approval date"
                    size="small"
                    variant="outlined"
                    rules={{
                        validate: () => (
                            firstApprovalDate && latestApprovalDate && firstApprovalDate > latestApprovalDate
                                ? 'First approval cannot be after latest approval'
                                : undefined)
                    }}
                />

                <DateField<AgencyApproval>
                    name="latestApprovalDate"
                    label="Latest approval date"
                    size="small"
                    variant="outlined"
                    required
                    rules={{
                        validate: () => (
                            firstApprovalDate && latestApprovalDate && firstApprovalDate > latestApprovalDate
                                ? 'Latest approval cannot be before first approval'
                                : undefined)
                    }}
                />
            </FormProvider>
        </form>
    );
}
