export enum DueDiligenceStepType {
    CUSTOM = 'CUSTOM',
    AGENCY = 'AGENCY',
    INSURANCE = 'INSURANCE',
    WAREHOUSE = 'WAREHOUSE',
    INVESTORS = 'INVESTORS'
}

export const dueDiligenceStepTypeDisplay = {
    [DueDiligenceStepType.CUSTOM]: 'Custom',
    [DueDiligenceStepType.AGENCY]: 'Agency approvals',
    [DueDiligenceStepType.INSURANCE]: 'Mortgage insurance relationships',
    [DueDiligenceStepType.WAREHOUSE]: 'Warehouse relationships',
    [DueDiligenceStepType.INVESTORS]: 'Investor relationships'
};

export const dueDiligenceStepTypeDisplaySingularLowercase = {
    [DueDiligenceStepType.CUSTOM]: 'custom',
    [DueDiligenceStepType.AGENCY]: 'agency approval',
    [DueDiligenceStepType.INSURANCE]: 'mortgage insurance relationship',
    [DueDiligenceStepType.WAREHOUSE]: 'warehouse relationship',
    [DueDiligenceStepType.INVESTORS]: 'investor relationship'
};

export const dueDiligenceStepTypeDisplayMultipleLowercase = {
    [DueDiligenceStepType.CUSTOM]: 'custom',
    [DueDiligenceStepType.AGENCY]: 'agency approvals',
    [DueDiligenceStepType.INSURANCE]: 'mortgage insurance relationships',
    [DueDiligenceStepType.WAREHOUSE]: 'warehouse relationships',
    [DueDiligenceStepType.INVESTORS]: 'investor relationships'
};
