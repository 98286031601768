import {
    ConditionEvent,
    ConditionExceptionStatus,
    LoanRoleAssignment,
    LoanStatusDelegated,
    LoanStatusNonDelegated,
    NotificationFrequency,
    NotificationMethod,
    NotificationType,
    getRoleURL
} from '../..';
import { apiUtils } from '../../api-utils';


export function getNotificationConfigs(clientID: string, roleID: string): Promise<NotificationConfig[]> {
    return apiUtils.get(getNotificationsUrl(clientID, roleID));
}

export function editNotification(
    clientID: string,
    roleID: string,
    notification: NotificationConfig
): Promise<NotificationConfig> {
    return apiUtils.put(getNotificationsUrl(clientID, roleID, notification.id), notification);
}

export function addNotification(
    clientID: string, roleID: string, notification: Omit<NotificationConfig, 'id'>
): Promise<NotificationConfig> {
    return apiUtils.post(getNotificationsUrl(clientID, roleID), notification);
}

export function deleteNotification(
    clientID: string,
    roleID: string,
    notificationID: string
): Promise<NotificationConfig> {
    return apiUtils.delete(getNotificationsUrl(clientID, roleID, notificationID));
}

export interface NotificationConfig {
    id: string;
    roleId: string;
    type: NotificationType;
    parameters: NotificationParam[];
    frequency: NotificationFrequency;
    reminderFrequency: NotificationFrequency;
    methods: NotificationMethod[];
    includeUnassigned: boolean;
}

export type NotificationParam =
    | ConditionEvent
    | ConditionExceptionStatus
    | LoanRoleAssignment
    | LoanStatusDelegated
    | LoanStatusNonDelegated;

function getNotificationsUrl(
    clientID: string,
    roleID: string,
    notificationID?: string
) {
    return `${getRoleURL(clientID, roleID)}/notification${notificationID ? `/${notificationID}` : ''}`;
}
