import api, { ClientInvestor, InvestorStatus, ManagedEligibilityStatus } from '@api';
import { AccountBalance, CorporateFare, InfoOutlined } from '@mui/icons-material';
import {
    Button, ButtonProps, Divider, Tooltip, Typography
} from '@mui/material';
import { IconTypography, Loader, TextField } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { InvestorEligibilityContext } from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import { InvestorFormFields } from '@views/admin/investors/InvestorDetailPage/components/InternalInvestorForm';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useCallback, useContext, useEffect, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './ClientInvestorForm.module.scss';


interface ClientInvestorFormProps {
    investorToEdit?: ClientInvestor;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setDisableSubmit?: Dispatch<SetStateAction<boolean>>;
}

export default function ClientInvestorForm({ investorToEdit, setLoading, setDisableSubmit } : ClientInvestorFormProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { search } = useLocation();

    const clientID = useActingClientID();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const { setInvestors } = useContext(InvestorEligibilityContext);

    const [ managedInvestors, setManagedInvestors ] = useState<ClientInvestor[]>();

    useAsyncEffect(useCallback(async () => {
        try {
            setManagedInvestors(await api.client.investors.getManagedInvestors(clientID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching managed agency investors', error);
        }
    }, [ pageMessage, clientID ]));

    const formMethods = useForm<ClientInvestor>({
        defaultValues: investorToEdit || {
            status: InvestorStatus.PENDING,
            eligibilityVersionId,
            managedEligibilityStatus: ManagedEligibilityStatus.UNMANAGED,
            addresses: [ {} ]
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues: ClientInvestor) => {
        if (clientID) {
            setLoading(true);

            try {
                const newInvestor = investorToEdit
                    ? await api.client.investors.updateInvestor(clientID, formValues)
                    : isAgency
                        ? await api.client.investors.activateManagedInvestor(clientID, {
                            sellerServicerNumber: formValues.sellerServicerNumber!,
                            investorId: agencyInvestorId,
                            eligibilityVersionId: String(eligibilityVersionId)
                        })
                        : await api.client.investors.addInvestor(clientID, formValues);

                setInvestors((investors = []) => (investorToEdit
                    ? replaceItemById(investors, newInvestor)
                    : investors.concat(newInvestor)));

                const isAdd = !formValues.id;

                navigate(isAdd ? `../investors/${newInvestor.id}${search}` : `../${search}`);

                pageMessage.success(isAdd ? 'Investor added' : 'Investor details saved');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while saving investor details', error);
            }

            setLoading(false);
        }
    });

    const isAgency = formMethods.watch('isAgency');
    const eligibilityBasedOnInvestorId = formMethods.watch('eligibilityBasedOnInvestorId');

    const [ agencyInvestorId, setAgencyInvestorId ] = useState('');

    const [ useAgencyBase, setUseAgencyBase ] = useState<boolean>();
    const allowSubmit = (isAgency && agencyInvestorId) || (
        isAgency === false && (useAgencyBase === false || eligibilityBasedOnInvestorId)
    );

    useEffect(() => {
        setDisableSubmit?.(!allowSubmit);
    }, [ allowSubmit, setDisableSubmit ]);

    const addInvestorContent = (
        <>
            <Typography align="center">
                Which type of investor would you like to add?
            </Typography>

            <div className={styles.agencyOrNonAgencyButtons}>
                <SelectableButton
                    selected={isAgency === true}
                    onClick={() => formMethods.setValue('isAgency', true)}
                    className={styles.agencyOrNonAgencyButton}
                >
                    <CorporateFare
                        color="secondary"
                        className={styles.icon}
                    />

                    Agency
                </SelectableButton>

                <SelectableButton
                    selected={isAgency === false}
                    onClick={() => formMethods.setValue('isAgency', false)}
                    className={styles.agencyOrNonAgencyButton}
                >
                    <AccountBalance
                        color="secondary"
                        className={styles.icon}
                    />

                    Non-Agency
                </SelectableButton>
            </div>

            {isAgency !== false && (
                <IconTypography
                    fontWeight={400}
                    variant="caption"
                    className={styles.help}
                    icon={(
                        <InfoOutlined
                            color="primary"
                            fontSize="small"
                        />
                    )}
                >
                    Base eligibility for agency investors is managed by Premicorr
                </IconTypography>
            )}

            {isAgency ? (
                <>
                    <Divider className={styles.divider} />

                    <Typography align="center">
                        Which agency investor would you like to activate?
                    </Typography>

                    {!managedInvestors ? (
                        <Loader loading />
                    ) : (
                        <div className={styles.agencyButtons}>
                            {managedInvestors.map(({ id, name, sellerServicerNumber }) => (
                                <SelectableButton
                                    key={id}
                                    size="small"
                                    selected={agencyInvestorId === id}
                                    onClick={() => setAgencyInvestorId(id)}
                                    disabled={!!sellerServicerNumber}
                                    title={sellerServicerNumber ? 'This agency investor is already active' : ''}
                                >
                                    {name}
                                </SelectableButton>
                            ))}
                        </div>
                    )}

                    {agencyInvestorId && (
                        <TextField<ClientInvestor>
                            name="sellerServicerNumber"
                            label="Seller servicer number"
                            required
                        />
                    )}
                </>
            ) : isAgency !== undefined && (
                <>
                    <Divider className={styles.divider} />

                    <Typography align="center">
                        Would you like to base eligibility for this investor on an agency investor?
                    </Typography>

                    <div className={styles.yesNoButtons}>
                        <SelectableButton
                            size="small"
                            selected={useAgencyBase}
                            onClick={() => setUseAgencyBase(true)}
                        >
                            Yes
                        </SelectableButton>

                        <SelectableButton
                            size="small"
                            selected={useAgencyBase === false}
                            onClick={() => setUseAgencyBase(false)}
                        >
                            No
                        </SelectableButton>
                    </div>

                    <IconTypography
                        fontWeight={400}
                        variant="caption"
                        className={styles.help}
                        icon={(
                            <InfoOutlined
                                color="primary"
                                fontSize="small"
                            />
                        )}
                    >
                        Base eligibility for agency investors is managed by Premicorr
                    </IconTypography>

                    {useAgencyBase && (
                        <>
                            <Divider className={styles.divider} />

                            <Typography align="center">
                                Which agency investor would you like to use as a base?
                            </Typography>

                            {!managedInvestors ? (
                                <Loader loading />
                            ) : (
                                <div className={styles.agencyButtons}>
                                    {managedInvestors.map(({ id, name }) => (
                                        <SelectableButton
                                            key={id}
                                            size="small"
                                            selected={eligibilityBasedOnInvestorId === id}
                                            onClick={() => formMethods.setValue(
                                                'eligibilityBasedOnInvestorId', id
                                            )}
                                        >
                                            {name}
                                        </SelectableButton>
                                    ))}
                                </div>
                            )}
                        </>
                    )}

                    {(useAgencyBase === false || eligibilityBasedOnInvestorId) && (
                        <>
                            <Divider className={styles.divider} />

                            <Typography
                                align="center"
                                className={styles.investorDetailsLabel}
                            >
                                Enter details for the new investor
                            </Typography>

                            <div className={styles.formFields}>
                                <InvestorFormFields hideAgencySwitch />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );

    return (
        <form
            id={ClientInvestorForm.formID}
            className={styles.root}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                {!investorToEdit ? addInvestorContent : isAgency
                    ? (
                        <TextField<ClientInvestor>
                            name="sellerServicerNumber"
                            label="Seller servicer number"
                            required
                        />
                    ) : (
                        <InvestorFormFields
                            hideAgencySwitch
                            disableCode
                        />
                    )}
            </FormProvider>
        </form>
    );
}

interface SelectableButtonProps extends ButtonProps {
    selected?: boolean;
    title?: string;
}

export function SelectableButton({
    className, children, selected, title, ...props
}: SelectableButtonProps) {
    return (
        <Tooltip title={title}>
            <span>
                <Button
                    variant="outlined"
                    {...props}
                    fullWidth
                    color={selected ? 'secondary' : 'primary'}
                    className={clsx(className, styles.selectableButton, {
                        [styles.selected]: selected
                    })}
                >
                    {children}
                </Button>
            </span>
        </Tooltip>
    );
}

ClientInvestorForm.formID = 'client-investor-form';
