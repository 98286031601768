import { ClientDetail } from '@api';
import { Typography } from '@mui/material';
import { AddressTypography, LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { useContext } from 'react';

import EditableSectionCard from '../../../components/EditableSectionCard';
import { ClientDetailsContext } from '../ClientDetailPage';

import styles from './ClientDetailSummaryCard.module.scss';


interface ClientDetailSummaryCardProps {
    client?: ClientDetail;
    readOnly?: boolean;
}

export default function ClientDetailSummaryCard({
    client: clientProp, readOnly
}: ClientDetailSummaryCardProps) {
    const contextClient = useContext(ClientDetailsContext).client!;
    const {
        legalName, displayName, nmlsId, code, address
    } = clientProp || contextClient;

    return (
        <EditableSectionCard
            header={legalName}
            editTo="edit"
            readOnly={readOnly}
        >
            <div className={styles.content}>
                <LabelGroup>
                    <LabeledValue
                        label="Display name:"
                        value={displayName}
                        variants={{ value: 'body1' }}
                    />

                    <LabeledValue
                        label="Code:"
                        value={code}
                        variants={{ value: 'body1' }}
                    />

                    <LabeledValue
                        label="NMLS ID:"
                        value={nmlsId}
                        variants={{ value: 'body1' }}
                    />
                </LabelGroup>

                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                    >
                        Address
                    </Typography>

                    <AddressTypography address={address} />
                </div>
            </div>
        </EditableSectionCard>
    );
}
