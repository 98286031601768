import { EligibilityGuideline } from '../investor/investor-api';

import { amortizationTypeDisplay } from './amorTypeEnum';
import { automatedUwSystemDisplay } from './automatedUwSystemEnum';
import { booleanEnumDisplay } from './booleanEnum';
import { loanPurposeDisplay } from './loanPurposeEnum';
import { loanTypeDisplay } from './loanTypeEnum';
import { occupancyTypeDisplay } from './occupancyTypeEnum';
import { propertyTypeDisplay } from './propertyTypeEnum';
import { specialtyProgramDisplay } from './specialtyProgramEnum';
import { stateDisplay } from './stateEnum';


export enum LoanProperty {
    LOAN_TYPE = 'LOAN_TYPE',
    OCCUPANCY = 'OCCUPANCY',
    PURPOSE = 'PURPOSE',
    AMORT_TYPE = 'AMORT_TYPE',
    PROPERTY_TYPE = 'PROPERTY_TYPE',
    UNITS = 'UNITS',
    FICO = 'FICO',
    LTV = 'LTV',
    CLTV = 'CLTV',
    DTI = 'DTI',
    TERM = 'TERM',
    AUS = 'AUS',
    RESERVES_MONTHS = 'RESERVES_MONTHS',
    HIGH_BALANCE = 'HIGH_BALANCE',
    SPECIALTY_PROGRAM = 'SPECIALTY_PROGRAM',
    STATE = 'STATE',
    LOAN_AMOUNT = 'LOAN_AMOUNT'
}

export enum LLPAOnlyLoanProperty {
    INVESTOR = 'INVESTOR'
}

export type LLPAMatrixLoanProperty = LoanProperty | LLPAOnlyLoanProperty;

export const loanPropertyDisplay = {
    [LoanProperty.LOAN_TYPE]: 'Loan Type',
    [LoanProperty.OCCUPANCY]: 'Occupancy',
    [LoanProperty.PURPOSE]: 'Purpose',
    [LoanProperty.AMORT_TYPE]: 'Amortization Type',
    [LoanProperty.PROPERTY_TYPE]: 'Property Type',
    [LoanProperty.UNITS]: 'Units',
    [LoanProperty.FICO]: 'FICO',
    [LoanProperty.LTV]: 'LTV',
    [LoanProperty.CLTV]: 'CLTV',
    [LoanProperty.DTI]: 'DTI',
    [LoanProperty.TERM]: 'Term',
    [LoanProperty.AUS]: 'AUS',
    [LoanProperty.RESERVES_MONTHS]: 'Min Reserves Months',
    [LoanProperty.HIGH_BALANCE]: 'High Balance',
    [LoanProperty.SPECIALTY_PROGRAM]: 'Specialty Program',
    [LoanProperty.STATE]: 'State',
    [LoanProperty.LOAN_AMOUNT]: 'Loan Amount'
} as const;

export const llpaMatrixLoanPropertyDisplay = {
    ...loanPropertyDisplay,
    [LLPAOnlyLoanProperty.INVESTOR]: 'Investor'
};

export const loanPropertyEnumFieldTypes = {
    [LoanProperty.LOAN_TYPE]: 'enum',
    [LoanProperty.OCCUPANCY]: 'enum',
    [LoanProperty.PURPOSE]: 'enum',
    [LoanProperty.AMORT_TYPE]: 'enum',
    [LoanProperty.PROPERTY_TYPE]: 'enum',
    [LoanProperty.UNITS]: 'numeric',
    [LoanProperty.FICO]: 'numeric',
    [LoanProperty.LTV]: 'numeric',
    [LoanProperty.CLTV]: 'numeric',
    [LoanProperty.DTI]: 'numeric',
    [LoanProperty.TERM]: 'numeric',
    [LoanProperty.AUS]: 'enum',
    [LoanProperty.RESERVES_MONTHS]: 'numeric',
    [LoanProperty.HIGH_BALANCE]: 'boolean',
    [LoanProperty.SPECIALTY_PROGRAM]: 'enum',
    [LoanProperty.STATE]: 'enum',
    [LoanProperty.LOAN_AMOUNT]: 'numeric'
} as const;

export const llpaMatrixLoanPropertyEnumFieldTypes = {
    ...loanPropertyEnumFieldTypes,
    [LLPAOnlyLoanProperty.INVESTOR]: 'enum'
} as const;

export const loanPropertyEligibilityGuidelineFieldNames: {
    [key in LoanProperty]: keyof EligibilityGuideline;
} = {
    [LoanProperty.LOAN_TYPE]: 'loanType',
    [LoanProperty.OCCUPANCY]: 'occupancy',
    [LoanProperty.PURPOSE]: 'purpose',
    [LoanProperty.AMORT_TYPE]: 'amortType',
    [LoanProperty.PROPERTY_TYPE]: 'propertyType',
    [LoanProperty.UNITS]: 'units',
    [LoanProperty.FICO]: 'fico',
    [LoanProperty.LTV]: 'ltv',
    [LoanProperty.CLTV]: 'cltv',
    [LoanProperty.DTI]: 'dti',
    [LoanProperty.TERM]: 'term',
    [LoanProperty.AUS]: 'aus',
    [LoanProperty.RESERVES_MONTHS]: 'reservesMonths',
    [LoanProperty.HIGH_BALANCE]: 'highBalance',
    [LoanProperty.SPECIALTY_PROGRAM]: 'specialtyProgram',
    [LoanProperty.STATE]: 'state',
    [LoanProperty.LOAN_AMOUNT]: 'loanAmount'
};

export const loanPropertyEnumDisplays = {
    [LoanProperty.LOAN_TYPE]: loanTypeDisplay,
    [LoanProperty.OCCUPANCY]: occupancyTypeDisplay,
    [LoanProperty.PURPOSE]: loanPurposeDisplay,
    [LoanProperty.AMORT_TYPE]: amortizationTypeDisplay,
    [LoanProperty.PROPERTY_TYPE]: propertyTypeDisplay,
    [LoanProperty.UNITS]: null,
    [LoanProperty.FICO]: null,
    [LoanProperty.LTV]: null,
    [LoanProperty.CLTV]: null,
    [LoanProperty.DTI]: null,
    [LoanProperty.TERM]: null,
    [LoanProperty.AUS]: automatedUwSystemDisplay,
    [LoanProperty.RESERVES_MONTHS]: null,
    [LoanProperty.HIGH_BALANCE]: booleanEnumDisplay,
    [LoanProperty.SPECIALTY_PROGRAM]: specialtyProgramDisplay,
    [LoanProperty.STATE]: stateDisplay,
    [LoanProperty.LOAN_AMOUNT]: null
} as const;

export const llpaLoanPropertyEnumDisplays = {
    ...loanPropertyEnumDisplays,
    [LLPAOnlyLoanProperty.INVESTOR]: null
} as const;
