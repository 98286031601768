import { Contact, PermissionType, User } from '@api';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { DialogActions, FilledSection } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks';
import { UserCard } from '@views/admin/users/UserCard';  // Updated import
import { Dispatch, SetStateAction } from 'react';

import { UserOrContact } from '../UsersAndContactsDialog';

import ContactCard from './ContactCard';
import styles from './UsersAndContactsListDialogContent.module.scss';


interface UsersAndContactsListDialogContentProps {
    users: User[];
    setUsers: Dispatch<SetStateAction<User[]>>;
    contacts: Contact[];
    setContacts: Dispatch<SetStateAction<Contact[]>>;
    onAddClick: (type: UserOrContact) => void;
    onEditClick: (item: Contact | User) => void;
}

export function UsersAndContactsListDialogContent({
    users, setUsers, contacts, setContacts, onAddClick, onEditClick
}: UsersAndContactsListDialogContentProps) {
    const [
        canViewContacts, canManageContacts, canViewUsers, canManageUsers
    ] = useHasPermission(
        [
            PermissionType.VIEW_CUSTOMER_CONTACTS,
            PermissionType.MANAGE_CUSTOMER_CONTACTS,
            PermissionType.VIEW_USERS,
            PermissionType.EDIT_USERS
        ]
    );

    return (
        <>
            <DialogContent className={styles.content}>
                <FilledSection
                    header="Users"
                    variant="light"
                    className={styles.section}
                >
                    {canViewUsers ? (
                        users.length ? (
                            users.map(user => (
                                <UserCard
                                    key={user.id}
                                    user={user}
                                    setUsers={setUsers}
                                    onEditClick={onEditClick}
                                    variant="outlined"
                                />
                            ))
                        ) : (
                            <div className={styles.root}>
                                No users exist for this customer
                            </div>
                        )
                    ) : (
                        <div className={styles.root}>
                            You do not have permission to view users.
                        </div>
                    )}
                </FilledSection>

                <FilledSection
                    header="Contacts"
                    variant="light"
                    className={styles.section}
                >
                    {canViewContacts ? (
                        contacts.length ? (
                            contacts.map(contact => (
                                <ContactCard
                                    key={contact.id}
                                    contact={contact}
                                    setContacts={setContacts}
                                    onEditClick={onEditClick}
                                />
                            ))
                        ) : (
                            <div className={styles.root}>
                                No contacts exist for this customer
                            </div>
                        )
                    ) : (
                        <div className={styles.root}>
                            You do not have permission to view contacts.
                        </div>
                    )}
                </FilledSection>
            </DialogContent>

            <DialogActions>
                <Tooltip title={!canManageContacts ? 'You do not have permission to add a contact' : ''}>
                    <span>
                        <Button
                            onClick={() => onAddClick('contact')}
                            disabled={!canManageContacts}
                        >
                            Add contact
                        </Button>
                    </span>
                </Tooltip>

                <Tooltip title={!canManageUsers ? 'You do not have permission to add a user' : ''}>
                    <span>
                        <Button
                            variant="contained"
                            onClick={() => onAddClick('user')}
                            disabled={!canManageUsers}
                        >
                            Add user
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </>
    );
}
