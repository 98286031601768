import { rest } from 'msw';

import { ActiveInactiveEnum, LicenseType, State } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { License } from './licenses-api';


let licenseID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/license'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(licenses)
    ))),
    rest.delete(getMockUrl('/client/:clientID/customer/:customerID/license/:licenseID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.patch(getMockUrl('/client/:clientID/customer/:customerID/license/:licenseID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(addedLicense)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/license/'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as License,
            id: `${licenseID++}`
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/license/:licenseID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

export const licenses: License[] = [
    {
        id: '7913bc54-aa5a-455f-a3a6-f6f757c55695',
        customerId: 25,
        state: State.CA,
        type: LicenseType.FEDERAL_LICENSE,
        licenseNumber: 'CA83920',
        renewedThroughYear: 2023,
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        id: '949a2b83-4ec4-435a-a8dd-7d1fff133496',
        customerId: 25,
        state: State.MO,
        type: LicenseType.MORTGAGE_BROKER_LICENSE,
        licenseNumber: '39208465G',
        renewedThroughYear: 2023,
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        id: '22815ba0-ce4d-42c5-bb71-50b456ae73ed',
        customerId: 25,
        state: State.MO,
        type: LicenseType.MORTGAGE_BANKER_LICENSE,
        licenseNumber: '87689',
        renewedThroughYear: 2023,
        status: ActiveInactiveEnum.INACTIVE
    },
    {
        id: '4a852413-65ec-4fa5-83ef-548b3a8e559a',
        customerId: 25,
        state: State.OR,
        type: LicenseType.MORTGAGE_LENDER_LICENSE,
        licenseNumber: '0937J4',
        renewedThroughYear: 2023,
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        id: 'd83795f3-cfe4-4183-8fea-aeebac2805cd',
        customerId: 25,
        state: State.FL,
        type: LicenseType.FEDERAL_LICENSE,
        licenseNumber: 'FL9980-23',
        renewedThroughYear: 2020,
        status: ActiveInactiveEnum.ACTIVE
    }
];

const addedLicense: License = {
    id: 'd83795f3-cfe4-4183-8fea-aeebac2805cd',
    customerId: 25,
    state: State.FL,
    type: LicenseType.FEDERAL_LICENSE,
    licenseNumber: 'Renewed License',
    renewedThroughYear: 2024,
    status: ActiveInactiveEnum.ACTIVE
};
