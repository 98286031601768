import { PermissionType } from '@api';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import { useHasPermission } from '@utils/hooks';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './DetailPageViewCard.module.scss';


interface DetailPageViewCardProps {
    label: ReactNode;
    to: LinkProps['to'];
    permission?: PermissionType; // TODO make this required when all perms are implemented
    noPermissionContent?: ReactNode;
}

export default function DetailPageViewCard({
    label, to, permission, noPermissionContent
}: DetailPageViewCardProps) {
    const [ hasPermission = true ] = useHasPermission(permission ? [ permission ] : []);

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <Typography variant="h6">
                {label}
            </Typography>

            <Tooltip title={hasPermission ? '' : noPermissionContent}>
                <span>
                    <Button
                        component={Link}
                        to={to}
                        disabled={!hasPermission}
                    >
                        View
                    </Button>
                </span>
            </Tooltip>
        </Paper>
    );
}
