import api, { ClientDetail, ClientStatus, clientStatusDisplay } from '@api';
import { Button } from '@mui/material';
import { updateClientTheme } from '@redux/auth';
import { RoutedDialogManager, SentryRoutes, usePageMessage } from '@tsp-ui/core/components';
import { getItemById, replaceItemById, useParams } from '@tsp-ui/core/utils';
import DetailPageViewCard from '@views/admin/components/DetailPageViewCard';
import InvestorEligibilityRoutes from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import {
    createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, Route } from 'react-router-dom';

import Page from '../../../components/Page';
import { AdminRouteParams } from '../../components/AdminPageTemplate';
import StatusActionsCard from '../../components/StatusActionsCard';
import { ClientManagementPageContext } from '../ClientManagementPage';

import styles from './ClientDetailPage.module.scss';
import ClientDetailSummaryCard from './components/ClientDetailSummaryCard';
import EditClientPrimaryContactDialog from './components/EditClientPrimaryContactDialog';
import { EditClientThemeDialog } from './components/EditClientThemeDialog';
import { ThemeAndLogoCard } from './components/ThemeAndLogoCard';


export interface ClientDetailsContextValue {
    client?: ClientDetail;
    updateClient: (updatedClient: ClientDetail) => void;
}

export const ClientDetailsContext = createContext<ClientDetailsContextValue>({
    client: undefined,
    updateClient: () => {}
});

export default function ClientDetailPage() {
    const { clientID } = useParams<AdminRouteParams<'client'>>();
    const dispatch = useDispatch();

    const { entities: clients, setEntities: setClients } = useContext(ClientManagementPageContext);
    const client = getItemById(clients, clientID);
    const updateClient = useCallback(
        (updatedClient: ClientDetail) => {
            dispatch(updateClientTheme({
                id: client.id,
                primaryColor: updatedClient.primaryColor,
                secondaryColor: updatedClient.secondaryColor,
                logoUrl: updatedClient.logoUrl
            }));

            setClients(clients => replaceItemById(clients, updatedClient));
        }, [
            setClients, client, dispatch
        ]
    );

    const pageMessage = usePageMessage();

    const [ statusLoading, setStatusLoading ] = useState(false);

    const clientContextValue = useMemo(() => ({
        client,
        updateClient
    }), [ client, updateClient ]);

    return (
        <SentryRoutes>
            <Route
                path="*"
                element={(
                    <Page
                        header="Client details"
                        headerActions={(
                            <Button
                                component={Link}
                                to="history"
                            >
                                View history
                            </Button>
                        )}
                        breadcrumbs={[
                            'Clients',
                            client?.legalName
                        ]}
                    >
                        {client && (
                            <ClientDetailsContext.Provider value={clientContextValue}>
                                <div className={styles.root}>
                                    <div>
                                        <ClientDetailSummaryCard />

                                        <ThemeAndLogoCard />
                                    </div>

                                    <div>
                                        <DetailPageViewCard
                                            label="Investor eligibility"
                                            to="investor-eligibility"
                                        />
                                    </div>

                                    <div>
                                        <StatusActionsCard
                                            actions={(
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    disabled={statusLoading}
                                                    onClick={async () => {
                                                        setStatusLoading(true);

                                                        try {
                                                            updateClient(await api.client.updateClient({
                                                                ...client,
                                                                status: client.status === ClientStatus.ACTIVE
                                                                    ? ClientStatus.INACTIVE
                                                                    : ClientStatus.ACTIVE
                                                            }));
                                                        } catch (error) {
                                                            pageMessage.handleApiError('An error occurred while updating client status', error);
                                                        }
                                                        setStatusLoading(false);
                                                    }}
                                                >
                                                    {client.status === ClientStatus.ACTIVE ? 'Deactivate' : 'Activate'}
                                                </Button>
                                            )}
                                            noBorder={false}
                                            status={clientStatusDisplay[client.status]}
                                            variant={client.status}
                                            isLoading={statusLoading}
                                        />

                                        {/* TODO add this back in later when real data is populated */}

                                        {/* <EditableSectionCard
                                            header="Primary contact"
                                            editTo="edit-primary-contact"
                                        >
                                            <ContactDisplay
                                                className={styles.clientDisplay}
                                                contact={client.primaryContact}
                                            />
                                        </EditableSectionCard> */}
                                    </div>
                                </div>

                                <RoutedDialogManager routes={dialogRoutes} />
                            </ClientDetailsContext.Provider>
                        )}
                    </Page>
                )}
            />

            <Route
                path="investor-eligibility/*"
                element={<InvestorEligibilityRoutes />}
            />
        </SentryRoutes>
    );
}

const dialogRoutes = {
    'edit-primary-contact': EditClientPrimaryContactDialog,
    'edit-theme': EditClientThemeDialog
};
