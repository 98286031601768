import api, {
    FeeAmountType, PurchaseAdviceFee, feeAmountPercentageOfDisplay, feeAmountTypeDisplay, feeTypeDisplay
} from '@api';
import {
    Button, DialogActions, DialogContent, Divider, Typography
} from '@mui/material';
import {
    CurrencyField, PercentField, RoutedDialog, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import {
    replaceItemById, useForm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './PurchaseAdviceFeeDialog.module.scss';
import { PurchaseAdviceContext } from './PurchaseAdviceManagementPage';


export function PurchaseAdviceFeeDialog() {
    const { feeID } = useParams<{ feeID: string }>();
    const { purchaseAdviceFees, setPurchaseAdviceFees } = useContext(PurchaseAdviceContext);
    const defaultValues = purchaseAdviceFees.find(fee => fee.id.toString() === feeID);
    const formMethods = useForm<PurchaseAdviceFee>({
        defaultValues,
        allowUndefinedDefaultValues: true
    });

    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    const amountType = formMethods.watch('amountType');

    useEffect(() => {
        if (amountType === FeeAmountType.FIXED) {
            formMethods.setValue('percentage', undefined);
            formMethods.setValue('percentageOf', undefined);
            formMethods.setValue('minAmount', undefined);
            formMethods.setValue('maxAmount', undefined);
        } else if (amountType === FeeAmountType.VARIABLE) {
            formMethods.setValue('amount', undefined);
        }
    }, [ amountType, formMethods ]);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        try {
            const newFee = defaultValues
                ? await api.purchaseAdvice.updatePurchaseAdviceFee(clientId, formData)
                : await api.purchaseAdvice.addPurchaseAdviceFee(clientId, formData);

            setPurchaseAdviceFees(feeID === 'new'
                ? [ ...purchaseAdviceFees, newFee ]
                : replaceItemById(purchaseAdviceFees, newFee));

            navigate('..');

            pageMessage.success('Fee saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the fee', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            closeTo=".."
            title={`${defaultValues ? 'Edit' : 'Add'} fee`}
            loading={loading}
        >
            <DialogContent>
                <form
                    id={PurchaseAdviceFeeDialog.formID}
                    onSubmit={handleSubmit}
                    className={styles.root}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<PurchaseAdviceFee>
                            name="type"
                            label="Fee type"
                            disabled={!!defaultValues}
                            select
                            required
                        >
                            {renderEnumOptions(feeTypeDisplay)}
                        </TextField>

                        <TextField<PurchaseAdviceFee>
                            name="amountType"
                            label="Amount type"
                            select
                            required
                        >
                            {renderEnumOptions(feeAmountTypeDisplay)}
                        </TextField>

                        {amountType === FeeAmountType.FIXED && (
                            <CurrencyField<PurchaseAdviceFee>
                                name="amount"
                                label="Fee amount"
                                required
                            />
                        )}

                        {amountType === FeeAmountType.VARIABLE && (
                            <>
                                <Divider />

                                <div className={styles.percentageRow}>
                                    <PercentField
                                        name="percentage"
                                        className={styles.percentageInput}
                                        percentVariant="adorned"
                                    />

                                    <Typography
                                        variant="body2"
                                        className={styles.separationText}
                                    >
                                        of the:
                                    </Typography>

                                    <TextField<PurchaseAdviceFee>
                                        className={styles.loanAmount}
                                        name="percentageOf"
                                        label="Loan amount type"
                                        select
                                        required
                                        hideRequiredIndicator
                                    >
                                        {renderEnumOptions(feeAmountPercentageOfDisplay)}
                                    </TextField>
                                </div>

                                <div className={styles.minMax}>
                                    <CurrencyField<PurchaseAdviceFee>
                                        name="minAmount"
                                        label="Min amount"
                                    />

                                    <CurrencyField<PurchaseAdviceFee>
                                        name="maxAmount"
                                        label="Max amount"
                                    />
                                </div>
                            </>
                        )}
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    form={PurchaseAdviceFeeDialog.formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

PurchaseAdviceFeeDialog.formID = 'add-edit-purchase-advice-fee-form';
