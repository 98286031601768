import { CustomerFeesComp, feeTypeDisplay } from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core';
import { useFormContext } from 'react-hook-form';

import styles from './CustomerCustomFeeCard.module.scss';
import CustomerFeeCard from './CustomerFeeCard';


interface CustomerCustomeFeeCardProps {
    index: number;
    onRemove: (index: number) => void;
}

export default function CustomerCustomFeeCard({
    index, onRemove
}: CustomerCustomeFeeCardProps) {
    const nameBase: `fees.${number}` = `fees.${index}`;

    const { watch } = useFormContext<CustomerFeesComp>();

    const formFee = watch(nameBase);

    return (
        <CustomerFeeCard
            fee={formFee}
            formIndex={index}
            onRemove={() => onRemove(index)}
            feeTypeDropdown={(
                <TextField<CustomerFeesComp>
                    name={`${nameBase}.type`}
                    SelectProps={{
                        classes: { select: styles.feeTypeDropdown }
                    }}
                    required
                    hideRequiredIndicator
                    select
                >
                    {renderEnumOptions(feeTypeDisplay)}
                </TextField>
            )}
        />
    );
}
