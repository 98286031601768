import {
    loanPropertyDisplay,
    loanPropertyEnumDisplays,
    loanPropertyEnumFieldTypes
} from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { numericMatrixEntryValidationRules } from '@utils/numeric-range-utils';
import {
    EligibilityExclusionFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow/ExclusionGroupRow';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import styles from './ExclusionRuleRow.module.scss';


interface ExclusionRuleRowProps {
    nameBase: string;
}

/**
 * Renders an exclusion rule row
 *
 * @param nameBase - The name base of the ExclusionRule that this row represents
 * @constructor
 */
export default function ExclusionRuleRow({ nameBase }: ExclusionRuleRowProps) {
    const { setValue, clearErrors } = useFormContext();
    const { loanProperty } = (useWatch({ name: nameBase }) || {}) as EligibilityExclusionFormValues;

    const fieldType = loanProperty && loanPropertyEnumFieldTypes[loanProperty];
    const enumDisplay = !loanProperty ? null : loanPropertyEnumDisplays[loanProperty];

    const fieldName = `${nameBase}.value`;

    return (
        <div className={styles.root}>
            <TextField
                name={`${nameBase}.loanProperty`}
                variant="standard"
                size="small"
                select
                required
                SelectProps={{
                    placeholder: 'Loan property',
                    classes: {
                        nativeInput: styles.placeholderSelect
                    }
                }}
                onChange={() => {
                    clearErrors(fieldName);
                    setValue(fieldName, '');
                }}
            >
                {renderEnumOptions(loanPropertyDisplay)};
            </TextField>

            {(fieldType === 'enum' || fieldType === 'boolean') ? (
                <TextField
                    key="select"
                    name={fieldName}
                    variant="standard"
                    size="small"
                    select
                    required
                    rules={{
                        pattern: undefined
                    }}
                    SelectProps={{
                        placeholder: 'Value',
                        classes: {
                            nativeInput: styles.placeholderSelect
                        }
                    }}
                >
                    {renderEnumOptions(enumDisplay!)}
                </TextField>
            ) : (
                <TextField
                    key="numeric"
                    name={fieldName}
                    variant="standard"
                    placeholder="Value"
                    size="small"
                    required
                    disabled={!loanProperty}
                    rules={loanProperty === '' ? undefined : numericMatrixEntryValidationRules}
                />
            )}
        </div>
    );
}
