import { apiUtils } from '../api-utils';


export async function getLoanDocuments(loanID: string): Promise<LoanDocument[]> {
    return await apiUtils.get(`/document/loan/${loanID}`);
}

// TODO post-demo replace uploadDocument with uploadDocuments
export function uploadDocument(loanID: string, formData: FormData): Promise<LoanDocument> {
    return apiUtils.post(`/document/loan/${loanID}`, formData, { useAutoContentType: true });
}

export async function getLoanDocumentURL(
    endpointURL: string
): Promise<string> {
    return URL.createObjectURL(new Blob(
        [ await fetch(endpointURL).then((response) => response.blob()) ],
        { type: 'application/pdf' }
    ));
}

export async function getDocument(clientID: string, documentID: string): Promise<Document> {
    return await apiUtils.get(`/client/${clientID}/document/${documentID}`);
}

export interface Document {
    id: string;
    url: string;
    preSignedUrl: string;
    expiration: string; // ISO date
}

export interface LoanDocument {
    id: string;
    loanId: string;
    name: string;
    containerName: string;
    instanceCount: number;
    url: string;
}
