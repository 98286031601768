
import { ActiveInactiveEnum, License, PermissionType } from '@api';
import {
    Button, DialogContent, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import { DialogActions, FilledSection } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { useState } from 'react';

import LicenseCard from './LicenseCard';
import styles from './LicenseListDialogContent.module.scss';


export interface LicenseListDialogContentProps {
    licenses: License[];
    onAddClick: () => void;
    onToggleActive: (id: string, status: ActiveInactiveEnum) => void;
    onEditClick: (license: License) => void;
    onRenew: (id: string, renewedThroughYear: number) => void;
    loading: boolean;
}

export default function LicenseListDialogContent({
    licenses, onAddClick, onToggleActive, onEditClick, onRenew, loading
}: LicenseListDialogContentProps) {
    const [ showInactive, setShowInactive ] = useState(false);

    const [ canAddLicense ] = useHasPermission([ PermissionType.CREATE_LICENSES ]);

    const currentYear = new Date().getFullYear();

    const active = licenses.filter(
        license => license.renewedThroughYear >= currentYear && license.status === ActiveInactiveEnum.ACTIVE
    );
    const inactive = licenses.filter(
        license => license.status === ActiveInactiveEnum.INACTIVE
    );
    const expired = licenses.filter(
        license => license.renewedThroughYear < currentYear && license.status === ActiveInactiveEnum.ACTIVE
    );

    return licenses.length ? (
        <>
            <DialogContent>
                <div className={styles.root}>
                    {showInactive && !!inactive.length && (
                        <FilledSection
                            variant="light"
                            header="Inactive"
                            className={styles.section}
                        >
                            {inactive.map((license) => (
                                <LicenseCard
                                    key={license.id}
                                    license={license}
                                    onToggleActive={onToggleActive}
                                    onEditClick={onEditClick}
                                    onRenew={onRenew}
                                />
                            ))}
                        </FilledSection>
                    )}

                    <FilledSection
                        variant="light"
                        header="Active"
                        className={styles.section}
                    >
                        {active.length ? active.map((license) => (
                            <LicenseCard
                                key={license.id}
                                license={license}
                                onToggleActive={onToggleActive}
                                onEditClick={onEditClick}
                                onRenew={onRenew}
                            />
                        )) : (
                            <Typography>
                                No active licenses exist for this customer.
                            </Typography>
                        )}
                    </FilledSection>

                    {!!expired.length && (
                        <FilledSection
                            header="Expired"
                            variant="light"
                            className={styles.section}
                        >
                            {expired.map((license) => (
                                <LicenseCard
                                    key={license.id}
                                    license={license}
                                    onToggleActive={onToggleActive}
                                    onEditClick={onEditClick}
                                    onRenew={onRenew}
                                />
                            ))}
                        </FilledSection>
                    )}
                </div>
            </DialogContent>

            <DialogActions loading={loading}>
                {inactive.length > 0 && (
                    <Button onClick={() => setShowInactive(!showInactive)}>
                        {showInactive ? 'Hide' : 'Show'} inactive
                    </Button>
                )}

                <Tooltip
                    title={canAddLicense ? '' : 'You do not have permission to add licenses'}
                    enterDelay={0}
                >
                    <span>
                        <Button
                            variant="contained"
                            onClick={onAddClick}
                            disabled={loading || !canAddLicense}
                        >
                            Add License
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </>
    ) : (
        <DialogContent>
            <Typography>
                No licenses exist for this customer.{' '}

                {canAddLicense && (
                    <>
                        <MuiLink onClick={onAddClick}>
                            Click here to add a new one
                        </MuiLink>.
                    </>
                )}
            </Typography>
        </DialogContent>
    );
}
