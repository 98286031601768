import {
    CommitmentType, ProductType, UnderwritingType
} from '../..';
import { apiUtils } from '../../api-utils';


export function updateEligibleProducts(
    clientID: string, customerID: string, productConfig: CustomerEligibleProducts
): Promise<CustomerEligibleProducts> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/product-eligibility`, productConfig);
}

export interface CustomerEligibleProducts {
    productTypeConfigs: ProductTypeConfig[];
    eligibleProductIds: string[];
}

export interface ProductTypeConfig {
    id: string;
    productType: ProductType;
    underwritingType: UnderwritingType;
    allowedCommitmentTypes: CommitmentType[];
    allowedLockPeriods: number[];
    isEnabled: boolean;
}
