import api, { CustomerWireInfo, stateDisplay } from '@api';
import { Button, DialogContent, FormLabel } from '@mui/material';
import { DialogActions, TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './WireInstructionsAddEditContent.module.scss';


const WIRE_INFO_FORM_ID = 'wire-info-form';

interface WireInstructionsAddEditContentProps {
    defaultValues: CustomerWireInfo | {};
    onAdd: (res: CustomerWireInfo) => void;
    onEdit: (formValues: CustomerWireInfo) => void;
    setWireInstructionsToEdit: Dispatch<SetStateAction<{} | CustomerWireInfo | undefined>>;
}

export default function WireInstructionsAddEditContent({
    defaultValues, onAdd, onEdit, setWireInstructionsToEdit
}: WireInstructionsAddEditContentProps) {
    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const { id: clientID } = useGetCurrentAccount();

    const formMethods = useForm<CustomerWireInfo>({ defaultValues });
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (formValues: CustomerWireInfo) => {
        setLoading(true);

        try {
            if (defaultValues.hasOwnProperty('id')) {
                onEdit(await api.customer.wireInfo.updateWireInfo(clientID, formValues));
            } else {
                onAdd(await api.customer.wireInfo.createWireInfo(clientID, customerID, formValues));
            }
            setLoading(false); // set loading before component unmounts

            setWireInstructionsToEdit(undefined);

            pageMessage.success('Wire instructions saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the wire instructions', error);

            setLoading(false);
        }
    });

    return (
        <>
            <DialogContent>
                <form
                    className={styles.root}
                    noValidate
                    id={WIRE_INFO_FORM_ID}
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <TextField<CustomerWireInfo>
                            className={styles.spanTwo}
                            name="bankName"
                            label="Bank name"
                            required
                        />

                        <TextField<CustomerWireInfo>
                            name="bankCity"
                            label="City"
                            required
                        />

                        <TextField<CustomerWireInfo>
                            name="bankState"
                            label="State"
                            select
                            required
                        >
                            {renderEnumOptions(stateDisplay)}
                        </TextField>

                        <TextField<CustomerWireInfo>
                            name="bankAba"
                            label="ABA"
                            required
                        />

                        <TextField<CustomerWireInfo>
                            name="bankAccountNum"
                            label="Account number"
                            required
                        />

                        <TextField<CustomerWireInfo>
                            className={styles.spanTwo}
                            name="wireDestination"
                            label="Wire destination"
                            required
                        />

                        <FormLabel
                            className={clsx(styles.spanTwo, styles.furtherCredit)}
                        >
                            Further credit to:
                        </FormLabel>

                        <TextField<CustomerWireInfo>
                            name="furtherCreditToAcctName"
                            label="Account name"
                        />

                        <TextField<CustomerWireInfo>
                            name="furtherCreditToAcctNum"
                            label="Account number"
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={() => setWireInstructionsToEdit(undefined)}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={WIRE_INFO_FORM_ID}
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
