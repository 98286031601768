import * as auth from './auth/auth-api';
import * as availability from './availability/availability-api';
import * as bulkCommitment from './bulk-commitments/bulk-commitment-api';
import * as client from './client/client-api';
import * as customer from './customer/customer-api';
import * as document from './document/document-api';
import * as dueDiligenceConfig from './due-diligence-config/due-diligence-api';
import * as eligibilityVersion from './eligibility-version/eligibility-version-api';
import * as overlay from './exclusion-overlay/exclusion-overlay-api';
import * as investors from './investor/investor-api';
import * as llpa from './llpa/llpa-api';
import * as loans from './loan/loan-api';
import * as notifications from './notification/notification-api';
import * as pricing from './pricing/pricing-api';
import * as products from './products/products-api';
import * as purchaseAdvice from './purchase-advice/purchaseAdvice-api';
import * as referenceGuides from './reference-guides/referenceGuides-api';
import * as roles from './role/role-api';
import * as users from './user/user-api';
import { PremicorrWebSocket } from './websocket/PremicorrWebSocket';


const api = {
    auth,
    availability,
    bulkCommitment,
    client,
    customer,
    dueDiligenceConfig,
    document,
    eligibilityVersion,
    investors,
    llpa,
    loans,
    notifications,
    overlay,
    pricing,
    products,
    purchaseAdvice,
    referenceGuides,
    roles,
    users,
    webSocket: new PremicorrWebSocket()
};

export default api;
// Need to export all of the types from clients and customers explicitly instead of just
// export * to avoid name collisions between sub-apis
export * from './auth/auth-api';
export type {
    ClientDetail, ClientProductConfig, AvailableProduct, ClientInvestor, ClientLoanProgram
} from './client/client-api';
export * from './client/customerAlertConfig/customerAlertConfig-api';
export * from './common';
export * from './availability/availability-api';
export * from './customer/branches/branches-api';
export * from './customer/due-diligence-step/due-diligence-step-api';
export * from './bulk-commitments/bulk-commitment-api';
export * from './customer/compensation/compensation-api';
export type { CustomerDetails, CustomerSummary } from './customer/customer-api';
export * from './customer/eventHistory/eventHistory-api';
export * from './customer/licenses/licenses-api';
export * from './customer/eligibleProducts/eligibleProducts-api';
export * from './customer/wireInfo/wireInfo-api';
export * from './customer/limits/limit-api';
export * from './customer/agency-approval/agency-approval-api';
export * from './customer/mi-company-relationship/mi-company-relationship-api';
export * from './customer/warehouse-relationship/warehouse-relationship-api';
export * from './customer/investor-relationship/investor-relationship-api';
export * from './due-diligence-config/due-diligence-api';
export * from './eligibility-version/eligibility-version-api';
export * from './document/document-api';
export * from './enums/alertConfigTypeEnum';
export * from './enums/feeAmountTypeEnum';
export * from './enums/feeAmountPercentageOfEnum';
export * from './enums/agencyEnum';
export * from './enums/loanAllocationLimitTypeEnum';
export * from './enums/amorTypeEnum';
export * from './enums/approvalStatusEnum';
export * from './enums/automatedUwRecommendationEnum';
export * from './enums/automatedUwSystemEnum';
export * from './enums/booleanEnum';
export * from './enums/dueDiligenceStepTypeEnum';
export * from './enums/clientSatusEnum';
export * from './enums/commitmentTypeEnum';
export * from './enums/conditionEventEnum';
export * from './enums/conditionExceptionStatusEnum';
export * from './enums/contactTitleEnum';
export * from './enums/contactTypeEnum';
export * from './enums/customerActionEnum';
export * from './enums/customerCompensationPercentageOf';
export * from './enums/customerCompensationTypeEnum';
export * from './enums/customerPendingStepsEnum';
export * from './enums/customerStatusEnum';
export * from './enums/activeInactiveEnum';
export * from './enums/documentationTypeEnum';
export * from './enums/feeTypeEnum';
export * from './enums/licenseTypeEnum';
export * from './enums/loanEventTypeEnum';
export * from './enums/loanPurposeEnum';
export * from './enums/loanPropertyEnum';
export * from './enums/loanRoleAssignmentEnum';
export * from './enums/loanStatusDelegatedEnum';
export * from './enums/loanStatusNonDelegatedEnum';
export * from './enums/loanTypeEnum';
export * from './enums/lockPeriodEnum';
export * from './enums/notificationFrequencyEnum';
export * from './enums/notificationMethodEnum';
export * from './enums/notificationTypeEnum';
export * from './enums/occupancyTypeEnum';
export * from './enums/operatorEnum';
export * from './enums/permissionCategoryEnum';
export * from './enums/permissionLevelEnum';
export * from './enums/permissionTypeEnum';
export * from './enums/productEventTypeEnum';
export * from './enums/productTypeEnum';
export * from './enums/propertyTypeEnum';
export * from './enums/registrationTypeEnum';
export * from './enums/roundingMethodEnum';
export * from './enums/servicingTypeEnum';
export * from './enums/specialtyProgramEnum';
export * from './enums/stateEnum';
export * from './enums/underwritingTypeEnum';
export * from './enums/userTypeEnum';
export * from './enums/loanStatusEnum';
export * from './enums/exclusionEqualityTypeEnum';
export * from './enums/numUnitsEnum';
export * from './enums/numericFieldOperatorEnum';
export * from './exclusion-overlay/exclusion-overlay-api';
export * from './investor/investor-api';
export * from './loan/loan-api';
export * from './llpa/llpa-api';
export * from './notification/notification-api';
export * from './pricing/pricing-api';
export * from './products/products-api';
export * from './purchase-advice/purchaseAdvice-api';
export * from './reference-guides/referenceGuides-api';
export * from './role/notifications/notifications-api';
export * from './role/role-api';
export * from './user/user-api';
export * from './websocket/WebSocketEventType';
export * from './websocket/PremicorrWebSocket';
