import api, { LockAvailabilityOverride } from '@api';
import { DateField, TextField, TimeField } from '@tsp-ui/core/components';
import { replaceItemByKey, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LockAvailabilityOverrideForm.module.scss';
import { LockAvailabilityContext } from './LockAvailabilityPage';


interface LockAvailabilityOverrideFormProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function LockAvailabilityOverrideForm({ setLoading }: LockAvailabilityOverrideFormProps) {
    const { id, customerID } = useParams();
    const { id: clientID } = useGetCurrentAccount();

    const { overriddenCustomerNames } = useContext(LockAvailabilityContext);
    const { lockAvailabilityOverrides, setLockAvailabilityOverrides } = useContext(LockAvailabilityContext);
    const defaultValues = lockAvailabilityOverrides.find(
        ({ lockAvailabilityOverrideId }) => lockAvailabilityOverrideId === id
    );

    const navigate = useNavigate();
    const location = useLocation();
    const pageMessage = usePageMessage();

    const formMethods = useForm<LockAvailabilityOverride>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        const updatedAvailabilities = {
            ...formData,
            lockAvailabilityDate: new Date(formData.lockAvailabilityDate).toISOString()
        };

        try {
            const updatedLockAvailabilityOverride = defaultValues
                ? await api.availability.updateLockAvailabilityOverride(clientID, updatedAvailabilities, customerID)
                : await api.availability.createLockAvailabilityOverride(clientID, {
                    ...updatedAvailabilities,
                    isAvailable: true
                }, customerID);

            setLockAvailabilityOverrides(
                defaultValues
                    ? replaceItemByKey<LockAvailabilityOverride>(
                        lockAvailabilityOverrides, updatedLockAvailabilityOverride, 'lockAvailabilityOverrideId'
                    )!
                    : lockAvailabilityOverrides.concat([ updatedLockAvailabilityOverride ])
            );

            navigate(`${location.pathname.split('lock-availability')[0]}lock-availability`);

            overriddenCustomerNames?.length !== undefined
                ? pageMessage.info('Lock availability saved. The following customers have overridden lock availabilities that were not affected', overriddenCustomerNames)
                : pageMessage.success('Lock availability saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the lock availability', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={LockAvailabilityOverrideForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <DateField<LockAvailabilityOverride>
                    name="lockAvailabilityDate"
                    label="Date"
                    required
                />

                <TextField<LockAvailabilityOverride>
                    name="description"
                    label="Description"
                    required
                />

                <TimeField<LockAvailabilityOverride>
                    name="lockStart"
                    label="Lock start"
                />

                <TimeField<LockAvailabilityOverride>
                    name="lockEnd"
                    label="Lock end"
                />
            </FormProvider>
        </form>
    );
}

LockAvailabilityOverrideForm.formID = 'lock-availability-override-form';
