import api, {
    ActiveInactiveEnum, DueDiligenceConfig, DueDiligenceStepType, dueDiligenceStepTypeDisplay
} from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, Switch, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { replaceItemById, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './DueDiligenceSettingsForm.module.scss';


interface DueDiligenceSettingsFormProps {
    config: DueDiligenceConfig | undefined;
    configs: DueDiligenceConfig[] | undefined;
    setConfigs: Dispatch<SetStateAction<DueDiligenceConfig[] | undefined>>;
    closeForm: () => void;
}

export default function DueDiligenceSettingsForm({
    config, configs, setConfigs, closeForm
}: DueDiligenceSettingsFormProps) {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);
    const formMethods = useForm<DueDiligenceConfig>({
        defaultValues: config || {
            ...emptyConfig,
            displayOrder: configs?.length || 0
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setLoading(true);

            const updatedConfig = config?.id
                ? await api.dueDiligenceConfig.updateDueDiligenceConfig(clientId, formValues)
                : await api.dueDiligenceConfig.createDueDiligenceConfig(clientId, formValues);

            setConfigs((configs) => (config?.id
                ? replaceItemById(configs || [], updatedConfig)
                : (configs || []).concat(updatedConfig)));

            closeForm();

            pageMessage.success(`Step ${config?.id ? 'saved' : 'added'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the due-diligence step', error);
        }

        setLoading(false);
    });

    const selectedTypes = configs?.filter(({ id }) => id !== config?.id).map(({ type }) => type) || [];

    const checklistStepEnumOptions = Object.fromEntries(
        Object.entries(dueDiligenceStepTypeDisplay).filter(([ key ]) => (
            key === DueDiligenceStepType.CUSTOM || !selectedTypes.includes(key as DueDiligenceStepType)
        ))
    );

    return (
        <>
            <DialogContent>
                <form
                    id={formID}
                    className={styles.root}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<DueDiligenceConfig>
                            name="type"
                            label="Step type"
                            size="small"
                            required
                            select
                        >
                            {renderEnumOptions(checklistStepEnumOptions)}
                        </TextField>

                        <TextField<DueDiligenceConfig>
                            name="name"
                            label="Step name"
                            size="small"
                            required
                        />

                        <TextField<DueDiligenceConfig>
                            name="description"
                            label="Step description"
                            size="small"
                            required
                            multiline
                            rows={4}
                        />

                        <div className={styles.switches}>
                            <Switch<DueDiligenceConfig>
                                label="Required"
                                name="isRequired"
                            />

                            <Switch<DueDiligenceConfig>
                                label="Document required"
                                name="isDocRequired"
                            />
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={closeForm}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={formID}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

const emptyConfig: Omit<DueDiligenceConfig, 'id'> = {
    name: '',
    description: '',
    status: ActiveInactiveEnum.ACTIVE,
    isDocRequired: false,
    displayOrder: 0,
    isRequired: true,
    type: DueDiligenceStepType.CUSTOM
};

const formID = 'due-diligence-config-form';
