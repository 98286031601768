export enum PermissionCategory {
    ROLES = 'ROLES',
    CLIENTS = 'CLIENTS',
    USERS = 'USERS',
    LOANS = 'LOANS',
    CUSTOMERS = 'CUSTOMERS',
    INVESTORS = 'INVESTORS',
    ELIGIBILITY = 'ELIGIBILITY',
    PRODUCTS = 'PRODUCTS',
    PURCHASE_ADVICE = 'PURCHASE_ADVICE',
    LOCK_AVAILABILITY = 'LOCK_AVAILABILITY',
    LOCK_SUSPENSIONS = 'LOCK_SUSPENSIONS',
    REFERENCE_GUIDES = 'REFERENCE_GUIDES',
    DUE_DILIGENCE_CONFIG = 'DUE_DILIGENCE_CONFIG',
    LLPAS = 'LLPAS',
    BULK_COMMITMENTS = 'BULK_COMMITMENTS',
}

export const permissionCategoryDisplay = {
    [PermissionCategory.ROLES]: 'Roles',
    [PermissionCategory.CLIENTS]: 'Clients',
    [PermissionCategory.USERS]: 'Users',
    [PermissionCategory.LOANS]: 'Loans',
    [PermissionCategory.CUSTOMERS]: 'Customers',
    [PermissionCategory.INVESTORS]: 'Investors',
    [PermissionCategory.ELIGIBILITY]: 'Eligibility',
    [PermissionCategory.PRODUCTS]: 'Products',
    [PermissionCategory.PURCHASE_ADVICE]: 'Purchase advice',
    [PermissionCategory.LOCK_AVAILABILITY]: 'Lock availability',
    [PermissionCategory.LOCK_SUSPENSIONS]: 'Lock suspensions',
    [PermissionCategory.REFERENCE_GUIDES]: 'Reference guides',
    [PermissionCategory.DUE_DILIGENCE_CONFIG]: 'Due diligence config',
    [PermissionCategory.LLPAS]: 'Llpas',
    [PermissionCategory.BULK_COMMITMENTS]: 'Bulk commitments'
};
