import api, { Document } from '@api';
import {
    CircularProgress, Fade, Link as MuiLink, Tooltip, Typography, TypographyProps
} from '@mui/material';
import { useIsTruncated, usePageMessage } from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils/hooks';
import { isAfter, parseISO } from 'date-fns';
import { useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import styles from './DocumentLink.module.scss';


interface DocumentLinkProps {
    document: Document;
    name: string;
    variant?: TypographyProps['variant'];
}

export function DocumentLink({ document: documentProp, name, variant }: DocumentLinkProps) {
    const pageMessage = usePageMessage();
    const { id: clientID } = useGetCurrentAccount();
    const [ { id, preSignedUrl, expiration }, setDocument ] = useState(documentProp);
    const expirationDate = parseISO(expiration);
    const [ loading, setLoading ] = useState(false);
    const [ debouncedLoading ] = useDebounce(loading, 300);

    const titleRef = useRef<HTMLDivElement>(null);
    const isTruncated = useIsTruncated(titleRef);

    const isExpired = isAfter(new Date(), expirationDate);

    const presignedUrlPromiseRef = useRef<Promise<Document>>();

    async function handleUrlRefresh() {
        // If a user triggers this to run multiple times before presignedUrlPromise returns,
        // we always want to return the presignedUrl so it can be opened, but we only want to update state once
        const isInitialRun = !presignedUrlPromiseRef.current;

        if (isExpired) {
            try {
                presignedUrlPromiseRef.current = presignedUrlPromiseRef.current || api.document.getDocument(
                    clientID, id
                );
                const { preSignedUrl, expiration } = await presignedUrlPromiseRef.current;

                if (isInitialRun) {
                    setDocument(document => ({
                        ...document,
                        preSignedUrl,
                        expiration
                    }));
                }

                presignedUrlPromiseRef.current = undefined;

                return preSignedUrl;
            } catch (error) {
                pageMessage.handleApiError(`An error occurred while fetching the url for ${name}`, error);
            }
        }
    }

    return (
        <span className={styles.root}>
            <Typography
                className={styles.fileTitle}
                ref={titleRef}
                variant={variant}
            >
                <Tooltip title={isTruncated ? name : ''}>
                    <MuiLink
                        onMouseOver={handleUrlRefresh}
                        onFocus={handleUrlRefresh}
                        onClick={async () => {
                            let urlToOpen = preSignedUrl;

                            if (isExpired) {
                                setLoading(true);
                                urlToOpen = await handleUrlRefresh() || '';
                                setLoading(false);
                            }

                            window.open(urlToOpen, '_blank');
                        }}
                    >
                        {name}
                    </MuiLink>
                </Tooltip>
            </Typography>

            {debouncedLoading && (
                <Fade in>
                    <CircularProgress
                        color="primary"
                        size={16}
                    />
                </Fade>
            )}
        </span>
    );
}
