import { PermissionType, TimeString } from '@api';
import {
    BlockOutlined, CheckCircleOutlined, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Alert, Paper, Typography
} from '@mui/material';
import { IconButton, ProgressIndicator } from '@tsp-ui/core/components';
import { formatTime } from '@tsp-ui/core/utils';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';

import styles from './LockAvailabilityCard.module.scss';


interface LockAvailabilityCardProps {
    isOverride: boolean;
    isAvailable: boolean;
    title: string;
    lockStart: TimeString | null | undefined;
    lockEnd: TimeString | null | undefined;
    date?: string;
    onEdit(): void;
    onToggle(): Promise<void>;
    onDelete?(): Promise<void>;
}

export function LockAvailabilityCard({
    isOverride, isAvailable, title, lockStart, lockEnd, date, onEdit, onToggle, onDelete
}: LockAvailabilityCardProps) {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const [ canEditLockAvailability ] = useHasPermission(
        [ PermissionType.EDIT_LOCK_AVAILABILITY ]
    );

    const [ canEditLockAvailabilityOverride ] = useHasPermission(
        [ PermissionType.EDIT_LOCK_AVAILABILITY_OVERRIDE ]
    );

    const [ canDeleteLockAvailabilityOverride ] = useHasPermission(
        [ PermissionType.DELETE_LOCK_AVAILABILITY_OVERRIDE ]
    );

    const canEdit = isOverride ? canEditLockAvailabilityOverride : canEditLockAvailability;
    const editToolTip = !canEdit ? `You do not have permission to edit lock availability ${isOverride ? 'override' : ''}` : 'Edit';

    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div>
                <Typography>
                    {title}
                </Typography>

                {isAvailable ? (
                    <Typography variant="body2">
                        {date && (
                            <strong>
                                {format(parseISO(date), 'M/dd/yyyy')},&nbsp;
                            </strong>
                        )}

                        {`${formatTime(lockStart)} - ${formatTime(lockEnd)}`}
                    </Typography>
                ) : (
                    <Alert
                        severity="warning"
                        icon={false}
                        className={styles.alert}
                    >
                        Lock desk closed on {date ? format(parseISO(date), 'M/dd/yyyy') : `${title}s`}
                    </Alert>
                )}
            </div>

            <div>
                {isAvailable && (
                    <IconButton
                        tooltip={editToolTip}
                        onClick={onEdit}
                        disabled={!canEdit}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                )}

                {isLoading ? <ProgressIndicator className={styles.progress} /> : (
                    <IconButton
                        tooltip={`${isAvailable ? 'Close' : 'Open'} lock desk`}
                        onClick={async () => {
                            setIsLoading(true);

                            await onToggle();

                            setIsLoading(false);
                        }}
                    >
                        {isAvailable ? <BlockOutlined color="action" /> : <CheckCircleOutlined color="success" />}
                    </IconButton>
                )}

                {isDeleting ? <ProgressIndicator /> : onDelete ? (
                    <IconButton
                        tooltip={canDeleteLockAvailabilityOverride ? 'Delete' : 'You do not have permission to delete lock availability overrides'}
                        onClick={async () => {
                            setIsDeleting(true);

                            await onDelete();

                            setIsDeleting(false);
                        }}
                        disabled={!canDeleteLockAvailabilityOverride}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                ) : undefined}
            </div>
        </Paper>
    );
}
