import {
    CustomerEligibleProducts,
    ProductType, commitmentTypeDisplay,
    lockPeriodDisplay,
    productTypeDisplay,
    underwritingTypeDisplay
} from '@api';
import { CheckCircleOutlined, Edit } from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import {
    IconButton, Switch, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { useConfirm } from '@tsp-ui/core/utils';
import { CustomerDetailContext } from '@views/admin/customers/CustomerDetailPage/CustomerDetailPage';
import { useContext, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
    CustomerEligibleProductsFormValues
} from '../EligibleProductsDialog';

import styles from './ProductTypeCard.module.scss';


interface ProductTypeCardProps {
    productType: ProductType;
}

export default function ProductTypeCard({ productType }: ProductTypeCardProps) {
    const confirm = useConfirm();
    const configIndex = useWatch<CustomerEligibleProductsFormValues, 'productTypeConfigs'>({
        name: 'productTypeConfigs'
    }).findIndex((config) => (
        config.productType === productType
    ));

    const { products } = useContext(CustomerDetailContext);

    const { setValue } = useFormContext<CustomerEligibleProductsFormValues>();

    const {
        isEnabled, underwritingType, allowedCommitmentTypes, allowedLockPeriods
    } = useWatch<CustomerEligibleProductsFormValues, `productTypeConfigs.${number}`>({
        name: `productTypeConfigs.${configIndex}`
    });

    const [ showEdit, setShowEdit ] = useState(false);
    const disableDone = !allowedCommitmentTypes.length || !allowedLockPeriods.length;
    const disableSwitch = !products?.find((product) => product.productType === productType && product.isActive);

    return (
        <Paper
            key={productType}
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.content}>
                <Tooltip title={disableSwitch ? 'There are no active products for this product type' : ''}>
                    <span className={styles.productTypeSwitch}>
                        <Switch<CustomerEligibleProducts>
                            label={productTypeDisplay[productType]}
                            name={`productTypeConfigs.${configIndex}.isEnabled`}
                            disabled={disableSwitch}
                            confirm={!isEnabled ? undefined : () => confirm(
                                `Deactivating a product type will also deactivate all products of that type.
                                 Are you sure you want to continue?`
                            )}
                            onChange={(_, checked) => {
                                if (!checked) {
                                    products?.filter((product) => product.productType === productType)
                                        .forEach((product) =>  {
                                            setValue(`eligibleProducts.${product.id}`, false);
                                        });
                                }

                                setShowEdit(checked);
                            }}
                        />
                    </span>
                </Tooltip>

                {showEdit ? (
                    <IconButton
                        tooltip={disableDone
                            ? 'Please fill in the required fields or disable this product type' : 'Done'}
                        onClick={() => setShowEdit(false)}
                        disabled={disableDone}
                    >
                        <CheckCircleOutlined color="success" />
                    </IconButton>
                ) : (
                    <>
                        {isEnabled && (
                            <>
                                <Typography
                                    variant="body2"
                                    className={styles.chip}
                                >
                                    {underwritingTypeDisplay[underwritingType]}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={styles.chip}
                                >
                                    {allowedCommitmentTypes.map((commitmentType) => commitmentTypeDisplay[commitmentType]).join(', ')}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={styles.chip}
                                >
                                    {allowedLockPeriods.join(', ')}
                                </Typography>
                            </>
                        )}

                        <IconButton
                            tooltip={isEnabled ? 'Edit' : 'Enable this product type to edit the details'}
                            onClick={() => setShowEdit(true)}
                            disabled={!isEnabled}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </>
                )}
            </div>

            {showEdit && (
                <div className={styles.fields}>
                    <TextField<CustomerEligibleProducts>
                        label="Underwriting type"
                        name={`productTypeConfigs.${configIndex}.underwritingType`}
                        size="small"
                        select
                    >
                        {renderEnumOptions(underwritingTypeDisplay)}
                    </TextField>

                    <TextField<CustomerEligibleProducts>
                        label="Commitment type(s)"
                        name={`productTypeConfigs.${configIndex}.allowedCommitmentTypes`}
                        size="small"
                        select
                        SelectProps={{ multiple: true }}
                        required
                    >
                        {renderEnumOptions(commitmentTypeDisplay)}
                    </TextField>

                    <TextField<CustomerEligibleProducts>
                        label="Lock periods"
                        name={`productTypeConfigs.${configIndex}.allowedLockPeriods`}
                        size="small"
                        select
                        SelectProps={{ multiple: true }}
                        required
                    >
                        {renderEnumOptions(lockPeriodDisplay)}
                    </TextField>
                </div>
            )}
        </Paper>
    );
}
