import {
    CommitmentType, ProductType, ProductTypeConfig, UnderwritingType
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';


export const mocks = [
    rest.put(getMockUrl('/client/:clientId/customer/:customerID/product-eligibility'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    ))
];

let productTypeConfig = 1;

export const mockedProductTypeConfigs: ProductTypeConfig[] = [
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.CONVENTIONAL,
        underwritingType: UnderwritingType.DELEGATED,
        allowedCommitmentTypes: [ CommitmentType.BEST_EFFORT ],
        allowedLockPeriods: [
            15, 30, 60
        ],
        isEnabled: true
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.VA,
        underwritingType: UnderwritingType.DELEGATED,
        allowedCommitmentTypes: [ CommitmentType.MANDATORY ],
        allowedLockPeriods: [ 60 ],
        isEnabled: true
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.NON_AGENCY,
        underwritingType: UnderwritingType.DELEGATED,
        allowedCommitmentTypes: [ CommitmentType.MANDATORY ],
        allowedLockPeriods: [ 60 ],
        isEnabled: false
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.FHA,
        underwritingType: UnderwritingType.NON_DELEGATED,
        allowedCommitmentTypes: [ CommitmentType.MANDATORY ],
        allowedLockPeriods: [
            15, 30, 60
        ],
        isEnabled: true
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.USDA,
        underwritingType: UnderwritingType.NON_DELEGATED,
        allowedCommitmentTypes: [ CommitmentType.MANDATORY ],
        allowedLockPeriods: [ 60 ],
        isEnabled: false
    }
];
