import api, {
    ActiveInactiveEnum, License, stateDisplay
} from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useCallback, useContext, useState } from 'react';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import AddEditLicenseDialogContent from './components/AddEditLicenseDialogContent';
import LicenseListDialogContent from './components/LicenseListDialogContent';


const licenseCompareFn = (a: License, b: License) => (
    stateDisplay[a.state].localeCompare(stateDisplay[b.state]) || a.type.localeCompare(b.type)
);

enum DialogType {
    ADD,
    EDIT,
    LIST
}

const { ADD, EDIT, LIST } = DialogType;

const dialogTitles = {
    [ADD]: 'Add license',
    [EDIT]: 'Edit license',
    [LIST]: 'State licenses'
};

export default function LicenseDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ dialogType, setDialogType ] = useState(LIST);
    const [ licenses, setLicenses ] = useState<License[]>([]);
    const [ defaultValues, setDefaultValues ] = useState<License>();
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const pageMessage = usePageMessage();
    const { refreshCustomer, customer } = useContext(CustomerDetailContext);
    const { id: customerID } = customer!;
    const { id: clientID } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        if (customerID) {
            setLoading(true);

            try {
                setLicenses(await api.customer.licenses.getLicenses(clientID, customerID));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching the licenses', error);
            }

            setLoading(false);
        }
    }, [
        clientID, customerID, pageMessage
    ]));

    async function toggleActive(licenseID: string, status: ActiveInactiveEnum) {
        if (customerID) {
            setSaveLoading(true);

            try {
                const updatedLicense = await api.customer.licenses.updateLicenseStatus(
                    clientID, customerID, licenseID, status
                );

                const updatedLicenseArray = replaceItemById(licenses, updatedLicense).sort(licenseCompareFn);

                const activeLength = updatedLicenseArray.filter(
                    license => license.status === ActiveInactiveEnum.ACTIVE
                ).length;

                if (
                    activeLength === 0 || (activeLength === 1 && status === ActiveInactiveEnum.ACTIVE)
                ) {
                    await refreshCustomer();
                }

                setLicenses(updatedLicenseArray);

                pageMessage.success('License status updated');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while updating the license status', error);
            }

            setSaveLoading(false);
        }
    }

    async function handleRenew(licenseID: string, renewedThroughYear: number) {
        if (customerID) {
            setSaveLoading(true);

            try {
                await api.customer.licenses.renewLicense(clientID, customerID, licenseID, renewedThroughYear);

                setLicenses(licenses.map(license => (license.id === licenseID ? {
                    ...license,
                    renewedThroughYear
                } : license)));

                pageMessage.success('License renewed');
            } catch (error) {
                setLicenses(licenses);

                pageMessage.handleApiError('An error occurred while renewing the license', error);
            }

            setSaveLoading(false);
        }
    }

    async function handleAdd(license: License) {
        setLicenses([ ...licenses, license ].sort(licenseCompareFn));

        await refreshCustomer();
    }

    function handleEdit(editedLicense: License) {
        setLicenses(replaceItemById(licenses, editedLicense).sort(licenseCompareFn));
    }

    function handleAddClick() {
        setDialogType(ADD);
        setDefaultValues(undefined);
    }

    function handleEditClick(license: License) {
        setDialogType(EDIT);
        setDefaultValues(license);
    }

    return (
        <RoutedDialog
            title={dialogTitles[dialogType]}
            {...props}
            loading={loading}
            maxWidth={false}
        >
            {dialogType === LIST ? (
                <LicenseListDialogContent
                    licenses={licenses}
                    onAddClick={handleAddClick}
                    onToggleActive={toggleActive}
                    onEditClick={handleEditClick}
                    onRenew={handleRenew}
                    loading={saveLoading}
                />
            ) : (
                <AddEditLicenseDialogContent
                    defaultValues={defaultValues}
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    onDone={() => setDialogType(LIST)}
                    {...props}
                />
            )}
        </RoutedDialog>
    );
}
