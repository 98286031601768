import api, {
    BulkCommitmentDetails, LoanPricingResult, PendingUpload, WebSocketEventType
} from '@api';
import {
    Button, MenuItem, Link as MuiLink, Tab, Tabs, TextField, Typography
} from '@mui/material';
import { ExpandableHeader, FileInput, RoutedDialogManager } from '@tsp-ui/core/components';
import { capitalize, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import LoanUploadResultsDialog from '@views/product-pricing/components/LoanUploadResultsDialog';
import { CommitmentTrackingCard } from '@views/tracking/bulk-commitment/BulkCommitmentTrackingPage';
import { isAfter, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useEffect, useState
} from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import Page from '../components/Page';

import styles from './ProductAndPricing.module.scss';
import LoanPricingResultCard from './components/LoanPricingResultCard';
import PendingUploadsCard from './components/PendingUploadsCard';


export const productAndPricingAcceptedFileTypes = [
    'xls', 'xlsx', 'csv', 'txt', 'fnm'
];

export default function ProductAndPricingPage() {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId: accountCustomerId } = useGetCurrentAccount();

    const [ tab, setTab ] = useState(0);
    const [ commitmentId, setCommitmentId ] = useState('10003');

    const [ pendingUploads, setPendingUploads ] = useState<PendingUpload[]>();
    const [ loanPricingResults, setLoanPricingResults ] = useState<LoanPricingResult[]>();
    const [ commitments, setCommitments ] = useState<BulkCommitmentDetails[]>();

    const [ floatedLoans, setFloatedLoans ] = useState<LoanPricingResult[]>();
    const [ loading, setLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        try {
            // TODO post-demo
            // const [ pricingResults, floatedLoans ] = await Promise.all(
            //     [ api.pricing.getPricingResults(), api.pricing.getFloatedLoans() ]
            // );
            // setLoanPricingResults(pricingResults);
            // setFloatedLoans(floatedLoans);

            setLoanPricingResults(await api.pricing.getPricingResults());
            setCommitments((await api.bulkCommitment.getCommitments(clientId)) as BulkCommitmentDetails[]);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the pending pricing results', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage, clientId ]));

    async function handleLoanUpload(files: FileList | File[]) {
        const formData = new FormData();
        [ ...files ].forEach(file => formData.append('files', file));

        try {
            setPendingUploads(await api.loans.uploadLoans(formData));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }
    }

    const [ loadingCommitmentIds, setLoadingCommitmentIds ] = useState<string[]>([]);
    async function handleBulkUpload(files: FileList | File[], commitmentId: string) {
        const formData = new FormData();
        [ ...files ].forEach(file => formData.append('files', file));

        try {
            setLoadingCommitmentIds(loadingCommitmentIds.concat(commitmentId));
            setPendingUploads(await api.bulkCommitment.uploadToCommitment(clientId, commitmentId, formData));
        } catch (error) {
            setLoadingCommitmentIds(loadingCommitmentIds.filter((id) => id !== commitmentId));
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }
    }

    const filteredCommitments = commitments?.filter(({ deliveryExpiration, customerId }) => (
        (!accountCustomerId ||  accountCustomerId === customerId)
            && isAfter(parseISO(deliveryExpiration), new Date())
    ));

    useEffect(() => api.webSocket.subscribe(
        WebSocketEventType.UPLOAD_COMPLETE,
        async ({ commitmentId }) => {
            setLoadingCommitmentIds((loadingCommitmentIds) => (
                loadingCommitmentIds.filter((id) => id !== commitmentId)
            ));

            const commitments = (await api.bulkCommitment.getCommitments(clientId)) as BulkCommitmentDetails[];
            setTimeout(() => setCommitments(commitments), 1000);
        }
    ), [ commitments, clientId ]);

    return  (
        <Page
            header={(
                <div className={styles.header}>
                    <span>Product & Pricing</span>

                    <Tabs value={tab}>
                        <Tab
                            label="Loans"
                            onClick={() => setTab(0)}
                        />

                        <Tab
                            label="Commitments"
                            onClick={() => setTab(1)}
                        />
                    </Tabs>
                </div>
            )}
            loading={loading}
            headerActions={(
                <Button>
                    View rate sheet
                </Button>
            )}
        >
            <div className={styles.root}>
                <div className={styles.sections}>
                    {tab === 0 ? (
                        <>
                            <ProductAndPricingSection
                                type="floated"
                                loanPricingResults={floatedLoans}
                                setLoanPricingResults={setLoanPricingResults}
                                setFloatedLoans={setFloatedLoans}
                            />

                            <ProductAndPricingSection
                                type="pending"
                                loanPricingResults={loanPricingResults}
                                setLoanPricingResults={setLoanPricingResults}
                                setFloatedLoans={setFloatedLoans}
                            />
                        </>
                    ) : filteredCommitments && (
                        <>
                            <Typography
                                fontWeight={500}
                                className={styles.sectionHeader}
                            >
                                Active commitments

                                <Typography
                                    color="textSecondary"
                                    fontWeight={400}
                                >
                                    {filteredCommitments.length} commitment

                                    {filteredCommitments.length === 1 ? '' : 's'}
                                </Typography>
                            </Typography>

                            <div className={styles.commitmentContainer}>
                                {filteredCommitments.map((commitment) => (
                                    <CommitmentTrackingCard
                                        key={commitment.id}
                                        toPathPrefix="../tracking/bulk-commitments"
                                        commitment={commitment}
                                        onAddFiles={handleBulkUpload}
                                        loading={loadingCommitmentIds.includes(commitment.id)}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <div className={styles.uploadLoansContainer}>
                    <Typography
                        fontWeight={500}
                        className={styles.sectionHeader}
                    >
                        Upload new loans
                    </Typography>

                    <div className={styles.fileInputContainer}>
                        {tab === 1 && (
                            <TextField
                                select
                                label="Deliver to"
                                sx={{ mb: 2 }}
                                value={commitmentId}
                                onChange={(event) => setCommitmentId(event.target.value)}
                            >
                                <MenuItem value="10003">
                                    Commitment #10003
                                </MenuItem>

                                <MenuItem value="10004">
                                    Commitment #10004
                                </MenuItem>

                                <MenuItem value="10005">
                                    Commitment #10005
                                </MenuItem>

                                <MenuItem value="10006">
                                    Commitment #10006
                                </MenuItem>
                            </TextField>
                        )}

                        <FileInput
                            compact
                            title="file"
                            acceptedFileTypes={productAndPricingAcceptedFileTypes}
                            onAddFiles={tab === 1 ? (files) => (
                                handleBulkUpload(files, commitmentId)
                            ) : handleLoanUpload}
                        />

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                        >
                            Don't have a file?
                            {' '}

                            <MuiLink
                                component={Link}
                                to="new/manual"
                            >
                                Add a loan manually
                            </MuiLink>

                            {' '}
                            instead
                        </Typography>
                    </div>

                    <Typography
                        fontWeight={500}
                        className={styles.sectionHeader}
                    >
                        Pending uploads
                    </Typography>

                    <PendingUploadsCard
                        pendingUploads={pendingUploads}
                        setLoanPricingResults={setLoanPricingResults}
                    />
                </div>
            </div>

            <RoutedDialogManager routes={routes} />
        </Page>
    );
}

const routes = {
    'uploads/*': LoanUploadResultsDialog
};

interface ProductAndPricingSectionProps {
    type: 'floated' | 'pending';
    loanPricingResults: LoanPricingResult[] | undefined;
    setLoanPricingResults: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
    setFloatedLoans: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
}

function ProductAndPricingSection({
    type, loanPricingResults, setLoanPricingResults, setFloatedLoans
}: ProductAndPricingSectionProps) {
    return (
        <div>
            <ExpandableHeader
                defaultExpand={type === 'pending'}
                disableExpand={!loanPricingResults?.length}
                title={`${capitalize(type)} loans`}
                secondaryText={loanPricingResults && (
                    loanPricingResults.length
                        ? `${loanPricingResults.length} loan${loanPricingResults.length > 1 ? 's' : ''}`
                        : `No loans are currently ${type}`
                )}
                expandedContent={(
                    <TransitionGroup className={styles.pricingResults}>
                        {loanPricingResults?.map((loanPricingResult, index) => (
                            <LoanPricingResultCard
                                key={loanPricingResult.loanId}
                                loanPricingResult={loanPricingResult}
                                updateLoanPricingResults={setLoanPricingResults}
                                updateFloatedLoans={setFloatedLoans}
                                index={index}
                            />
                        )) || (
                            <Typography>
                                There are no loan pricing results to display at this time.
                            </Typography>
                        )}
                    </TransitionGroup>
                )}
            />
        </div>
    );
}
