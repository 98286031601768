import api, { ClientDetail, ClientStatus } from '@api';
import { Tooltip } from '@mui/material';
import { AddressFieldset, Switch, TextField } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useCreateAccountUrl } from '@utils/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../../components/AdminAddPageTemplate';

import styles from './ClientForm.module.scss';


export default function ClientForm({
    entityToEdit: clientToEdit,
    onSubmit,
    setLoading
} : AdminAddEditFormProps<ClientDetail>) {
    const navigate = useNavigate();
    const createAccountUrl = useCreateAccountUrl();

    const pageMessage = usePageMessage();
    const formMethods = useForm<ClientDetail>({
        defaultValues: clientToEdit
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues: ClientDetail) => {
        setLoading(true);

        try {
            const client = clientToEdit
                ? await api.client.updateClient(formValues)
                : await api.client.createClient({
                    ...formValues,
                    status: ClientStatus.PENDING
                });

            onSubmit(client);

            setLoading(false);

            navigate(createAccountUrl(`admin/clients/${client.id}`));

            pageMessage.success('Client details saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the client details', error);
            setLoading(false);
        }
    });

    return (
        <form
            id={ClientForm.formID}
            className={styles.root}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                <TextField<ClientDetail>
                    name="legalName"
                    label="Client legal name"
                    required
                />

                <TextField<ClientDetail>
                    name="displayName"
                    label="Display name"
                    required
                />

                <TextField<ClientDetail>
                    name="nmlsId"
                    label="NMLS ID"
                    required
                />

                <Tooltip title={clientToEdit ? 'Code can not be changed' : ''}>
                    <span>
                        <TextField<ClientDetail>
                            name="code"
                            label="Code"
                            required
                            disabled={!!clientToEdit}
                            fullWidth
                        />
                    </span>
                </Tooltip>

                <AddressFieldset<ClientDetail>
                    className={styles.fullWidth}
                    fieldNames={{
                        street: 'address.street',
                        line2: 'address.line2',
                        city: 'address.city',
                        state: 'address.state',
                        zip: 'address.zip'
                    }}
                    required
                />

                <Switch<ClientDetail>
                    name="allowInternalEdits"
                    label="Allow internal users to edit"
                    className={styles.fullWidth}
                />
            </FormProvider>
        </form>
    );
}

ClientForm.formID = 'edit-client-form';
