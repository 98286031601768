import { ExclusionOverlay, ExclusionOverlayType } from '@api';
import { Button, Tooltip, Typography } from '@mui/material';
import { useParams } from '@tsp-ui/core';
import { FilterTextField, FilterableFilledSection } from '@tsp-ui/core/components';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import { ExclusionOverlayCard } from '@views/admin/investor-eligibility/components/ExclusionOverlayCard';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ExclusionOverlaySection.module.scss';


interface ExclusionOverlaySectionProps {
    overlays: ExclusionOverlay[];
    disableAdd?: boolean;
}

export default function ExclusionOverlaySection({ overlays, disableAdd }: ExclusionOverlaySectionProps) {
    const { loanProgramID: loanProgramId, investorID: investorId } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { search } = useLocation();

    const variant = loanProgramId ? 'loan-program' : investorId ? 'investor' : 'client';

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);
    const filteredOverlays = overlays.filter(overlay => overlay.name.toLocaleLowerCase().includes(debouncedTerm));

    const clientOverlays = filteredOverlays.filter(({ type }) => type === ExclusionOverlayType.CLIENT);
    const investorOverlays = filteredOverlays.filter(({ type }) => type === ExclusionOverlayType.INVESTOR);
    const loanProgramOverlays = filteredOverlays.filter(({ type }) => type === ExclusionOverlayType.LOAN_PROGRAM);

    const { investors } = useContext(InvestorEligibilityContext);

    return (
        <FilterableFilledSection
            header="Exclusion overlays"
            addButton={(
                <Tooltip
                    title={disableAdd ? 'You can only add investors to a future eligibility version' : ''}
                >
                    <span>
                        <Button
                            variant="contained"
                            component={Link}
                            to={`overlays/add${search}`}
                            disabled={disableAdd}
                        >
                            Add exclusion overlay
                        </Button>
                    </span>
                </Tooltip>
            )}
            filterField={(
                <FilterTextField
                    placeholder="Filter exclusion overlays"
                    helperText="Filter by name"
                    onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                />
            )}
            caption={variant === 'client' ? (
                <>
                    Only includes exclusion overlays that apply to more than one investor. View additional{' '}
                    exclusions on an individual Investor or Loan Program.
                </>
            ) : variant === 'investor' ? (
                <>
                    Only includes exclusion overlays that apply to more than one Loan Program for{' '}
                    this investor. View additional excusions on an individual Loan Program.
                </>
            ) : undefined}
        >
            {variant !== 'client' && (
                <Typography
                    variant="caption"
                    color="textSecondary"
                    className={styles.exclusionGroup}
                >
                    Global
                </Typography>
            )}

            {clientOverlays?.length ? clientOverlays?.map(overlay => (
                <ExclusionOverlayCard
                    key={overlay.id}
                    overlay={overlay}
                    confirmMessage={variant !== 'client'
                        ? `Modifying this overlay will affect more than just this
                         ${variant === 'loan-program' ? 'loan program' : 'investor'}. Do you want to continue?`
                        : undefined}
                />
            )) : 'No global overlays are currently configured.'}

            {variant !== 'client' && (
                <>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.exclusionGroup}
                    >
                        {investors?.find(investor => investor.id === investorId)?.name}
                    </Typography>

                    {investorOverlays?.length ? investorOverlays?.map(overlay => (
                        <ExclusionOverlayCard
                            key={overlay.id}
                            overlay={overlay}
                            confirmMessage={variant === 'loan-program'
                                ? `Modifying this overlay will affect more than just this loan program.
                                   Do you want to continue?`
                                : undefined}
                        />
                    )) : 'No overlays for this investor are currently configured.'}
                </>
            )}

            {variant === 'loan-program' && (
                <>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.exclusionGroup}
                    >
                        This loan program
                    </Typography>

                    {loanProgramOverlays?.length ? loanProgramOverlays?.map(overlay => (
                        <ExclusionOverlayCard
                            key={overlay.id}
                            overlay={overlay}
                        />
                    )) : 'No overlays for this loan program are currently configured.'}
                </>
            )}
        </FilterableFilledSection>
    );
}
