import api, {
    CommonLoanProgram,
    EligibilityMatrix,
    EligibilityVersion,
    LoanProgramStatus,
    ManagedEligibilityStatus,
    PermissionType
} from '@api';
import {
    Button, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, tryGetItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useHasPermission, withAuth } from '@utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import StatusActionsCard from '@views/admin/components/StatusActionsCard';
import { InternalEligibilityVersionsContext } from '@views/admin/investors/InternalInvestorManagementPage';
import { useTryGetInvestor } from '@views/admin/investors/InvestorDetailPage/InternalInvestorDetailPage';
import {
    InternalLoanProgramEligibilityExclusionsDialog
} from '@views/admin/investors/InvestorDetailPage/components/InternalInvestorEligibilityExclusionsDialog';
import EligibilityExclusionsCard from '@views/admin/investors/components/EligibilityExclusionsCard';
import {
    EligibilityVersionButton,
    useEligibilityVersionIdQueryParam
} from '@views/admin/investors/components/EligibilityVersionButton';
import Page from '@views/components/Page';
import {
    Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LoanProgramsContext } from '../../InternalInvestorDetailRoutes';

import styles from './InternalLoanProgramDetailPage.module.scss';
import { EditInternalEligibilityMatrixDialog } from './components/EditInternalEligibilityMatrixDialog';
import { EditInternalLoanProgramDetailsDialog } from './components/EditLoanProgramDetailsDialog';
import LoanProgramDetailsCard from './components/LoanProgramDetailsCard';
import InternalReadOnlyEligibilityMatrixDialog from './components/ReadOnlyEligibilityMatrixDialog';


const { ACTIVE, INACTIVE } = LoanProgramStatus;

export function useTryGetLoanProgram(): [ CommonLoanProgram | undefined, (
    updatedLoanProgram: CommonLoanProgram
) => void, boolean] {
    const { loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { loanPrograms, setLoanPrograms } = useContext(LoanProgramsContext);

    const loanProgram = tryGetItemById(loanPrograms || [], loanProgramID);

    function setLoanProgram(updatedLoanProgram: CommonLoanProgram) {
        setLoanPrograms(loanPrograms => replaceItemById(loanPrograms || [], updatedLoanProgram));
    }

    const isLoading = !loanPrograms;

    return [
        loanProgram, setLoanProgram, isLoading
    ];
}

export function useGetLoanProgram() {
    return useTryGetLoanProgram() as [ CommonLoanProgram, (updatedLoanProgram: CommonLoanProgram) => void, boolean ];
}

export const InternalLoanProgramDetailPageContext = createContext<{
    matrix: EligibilityMatrix | undefined;
    setMatrix: Dispatch<SetStateAction<EligibilityMatrix | undefined>>;
}>({
    matrix: undefined,
    setMatrix: () => {}
});

export function InternalLoanProgramDetailPage() {
    const pageMessage = usePageMessage();
    const { search } = useLocation();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_ELIGIBILITY ]);

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();

    const { eligibilityVersions, isSelectedVersionEditable } = useContext(InternalEligibilityVersionsContext);

    const [ matrix, setMatrix ] = useState<EligibilityMatrix>();
    useAsyncEffect(useCallback(async () => {
        try {
            setMatrix(await api.investors.getEligibilityMatrix(investorID, loanProgramID, eligibilityVersionId));
        } catch (error) {
            pageMessage.handleApiError(
                'An error occurred while fetching eligibility details for this loan program', error
            );
        }
    }, [
        investorID, loanProgramID, eligibilityVersionId, pageMessage
    ]));

    return (
        <LoanProgramDetailPage
            matrix={matrix}
            setMatrix={setMatrix}
            firstBreadcrumb="Investors"
            eligibilityVersions={eligibilityVersions}
            editPermission={PermissionType.MANAGE_LOAN_PROGRAMS}
            isSelectedVersionEditable={isSelectedVersionEditable}
            onToggleActive={(loanProgram) => api.investors.updateLoanProgramStatus(
                loanProgram.investorId,
                loanProgram.id,
                {
                    status: loanProgram.status === ACTIVE ? INACTIVE : ACTIVE,
                    eligibilityVersionId: eligibilityVersionId!
                }
            )}
        >
            <EditableSectionCard
                header="Eligibility matrix"
                editTo={`matrix/edit${search}`}
                disableEdit={!canManage || !isSelectedVersionEditable}
                disabledTooltip={!canManage
                    ? 'You don\'t have permission to manage eligibility'
                    : 'The eligibility matrix can only be edited for a future eligibility version'}
            >
                <Typography>
                    Eligibility for this program is managed by Premicorr
                </Typography>

                <Button
                    component={Link}
                    to={`matrix${search}`}
                    className={styles.viewButton}
                >
                    View eligibility matrix
                </Button>
            </EditableSectionCard>

            <EligibilityExclusionsCard
                exclusions={matrix?.exclusions}
                isSelectedVersionEditable={isSelectedVersionEditable}
                caption="These exclusions apply only to this loan program"
            />

            <RoutedDialogManager routes={dialogRoutes} />
        </LoanProgramDetailPage>
    );
}

const { VIEW_ELIGIBILITY, MANAGE_ELIGIBILITY, MANAGE_LOAN_PROGRAMS } = PermissionType;

const dialogRoutes = {
    matrix: withAuth(InternalReadOnlyEligibilityMatrixDialog, [ VIEW_ELIGIBILITY ], true),
    'matrix/edit': withAuth(EditInternalEligibilityMatrixDialog, [ MANAGE_ELIGIBILITY ], true),
    edit: withAuth(EditInternalLoanProgramDetailsDialog, [ MANAGE_LOAN_PROGRAMS ], true),
    exclusions: withAuth(
        InternalLoanProgramEligibilityExclusionsDialog, [ MANAGE_ELIGIBILITY ], true
    )
};

interface LoanProgramDetailPageProps {
    children: ReactNode;
    matrix: EligibilityMatrix | undefined;
    setMatrix: Dispatch<SetStateAction<EligibilityMatrix | undefined>>;
    onToggleActive: (loanProgram: CommonLoanProgram) => Promise<CommonLoanProgram>;
    firstBreadcrumb: ReactNode;
    eligibilityVersions: EligibilityVersion[] | undefined;
    isSelectedVersionEditable: boolean;
    editPermission: PermissionType;
    loading?: boolean;
}

export function LoanProgramDetailPage({
    children, matrix, setMatrix, onToggleActive, eligibilityVersions, firstBreadcrumb, editPermission,
    isSelectedVersionEditable, loading
}: LoanProgramDetailPageProps) {
    const pageMessage = usePageMessage();
    const { search } = useLocation();

    const [ investor ] = useTryGetInvestor();

    const [
        loanProgram, setLoanProgram, isLoading
    ] = useTryGetLoanProgram();

    const [ statusLoading, setStatusLoading ] = useState(false);
    async function updateStatus() {
        try {
            if (loanProgram) {
                setStatusLoading(true);
                setLoanProgram(await onToggleActive(loanProgram));

                pageMessage.success('Loan program status updated');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating loan program status', error);
        }

        setStatusLoading(false);
    }

    const providerValue = useMemo(() => ({
        matrix,
        setMatrix
    }), [ matrix, setMatrix ]);

    const [ canEdit ] = useHasPermission([ editPermission ]);

    return (
        <InternalLoanProgramDetailPageContext.Provider value={providerValue}>
            <Page
                header="Loan Program Details"
                headerActions={(
                    <EligibilityVersionButton eligibilityVersions={eligibilityVersions} />
                )}
                loading={isLoading || !matrix || loading}
                breadcrumbs={[
                    firstBreadcrumb,
                    investor?.name,
                    loanProgram?.name
                ]}
            >
                {!loanProgram ? (
                    <Typography>
                        This loan program does not exist.{' '}

                        <MuiLink
                            component={Link}
                            to={`../loan-programs/new${search}`}
                        >
                            Click here
                        </MuiLink> to add a new one.
                    </Typography>
                ) : (
                    <div className={styles.root}>
                        <LoanProgramDetailsCard
                            disableEdit={editPermission === PermissionType.MANAGE_CLIENT_LOAN_PROGRAMS
                                && investor?.managedEligibilityStatus === ManagedEligibilityStatus.MANAGED}
                        />

                        <StatusActionsCard
                            isLoading={statusLoading}
                            actions={(
                                <Tooltip
                                    title={statusLoading ? 'Loading...' : !canEdit
                                        ? 'You don\'t have permission to manage loan programs'
                                        : !isSelectedVersionEditable
                                            ? 'You can only change loan program status for a future eligibility version'
                                            : ''}
                                >
                                    <span>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={updateStatus}
                                            disabled={statusLoading || !isSelectedVersionEditable || !canEdit}
                                        >
                                            {loanProgram.status === ACTIVE
                                                ? 'Deactivate'
                                                : 'Activate'}
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            noBorder={false}
                            status={{
                                [ACTIVE]: 'Active',
                                [INACTIVE]: 'Inactive'
                            }[loanProgram.status]}
                            variant={loanProgram.status}
                        />

                        {children}
                    </div>
                )}
            </Page>
        </InternalLoanProgramDetailPageContext.Provider>
    );
}
