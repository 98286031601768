import api, {
    CustomerStatus, CustomerSummary, PermissionType, customerStatusDisplay
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { withAuth } from '@utils/withAuth';
import DueDiligenceConfigDialog from '@views/admin/customers/components/DueDiligenceConfigDialog/DueDiligenceConfigDialog';
import { createContext, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import AdminPageTemplate, {
    AdminEntityGroup,
    AdminEntityGroupProps,
    AdminPageContextValues,
    defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import CustomerDetailPage from './CustomerDetailPage/CustomerDetailPage';
import CustomerForm from './CustomerForm';
import { CustomerCard } from './components/CustomerCard';


const { ACTIVE, INACTIVE, PENDING } = CustomerStatus;

export const CustomerManagementContext = createContext<AdminPageContextValues<CustomerSummary>>(
    defaultAdminPageContextValues
);

export default function CustomerManagementPage() {
    const { id: clientId } = useGetCurrentAccount();
    const [ showInactive, setShowInactive ] = useState(false);

    const fetchCustomers = useCallback(() => (
        api.customer.getCustomers(clientId)
    ), [ clientId ]);

    const [ canAddCustomer, canViewDueDiligenceConfig ] = useHasPermission(
        [ PermissionType.CREATE_CUSTOMERS, PermissionType.VIEW_DUE_DILIGENCE_CONFIG ]
    );

    return (
        <AdminPageTemplate
            Context={CustomerManagementContext}
            disableAddEntity={!canAddCustomer}
            disabledAddTooltip="You don't have permission to add a customer"
            CreateEditForm={CustomerForm}
            DetailPage={CustomerDetailPage}
            entityName="customer"
            EntityGroupComponent={CustomerGroup}
            fetchEntities={fetchCustomers}
            filterByLabel="name or NMLS ID"
            filterEntity={({ name, nmlsId }, filterInputValue) => (
                name.toLocaleLowerCase().includes(filterInputValue) || nmlsId.includes(filterInputValue)
            )}
            headerActions={(
                <Tooltip
                    title={!canViewDueDiligenceConfig
                        ? 'You don\'t have permission to view due diligence configuration' : ''}
                >
                    <span>
                        <Button
                            component={Link}
                            to="due-diligence"
                            disabled={!canViewDueDiligenceConfig}
                        >
                            View due-diligence steps
                        </Button>
                    </span>
                </Tooltip>
            )}
            otherFilters={(
                <Button onClick={() => setShowInactive(!showInactive)}>
                    {showInactive ? 'Hide' : 'Show'} inactive
                </Button>
            )}
            sortEntitiesBy="name"
            visibleGroups={[
                PENDING,
                ACTIVE,
                ...(showInactive ? [ INACTIVE ] : [])
            ]}
            dialogRoutes={dialogRoutes}
        />
    );
}

const dialogRoutes = {
    'due-diligence': withAuth(
        DueDiligenceConfigDialog, [ PermissionType.VIEW_DUE_DILIGENCE_CONFIG ], true
    )
};

function CustomerGroup({
    entities: customers,
    group: statusProp
}: AdminEntityGroupProps<CustomerSummary, CustomerStatus>) {
    return (
        <AdminEntityGroup
            header={(
                <>
                    {customerStatusDisplay[statusProp]}

                    {statusProp === PENDING && (
                        <Tooltip title="Pending customers are not yet active. Complete required items to activate them.">
                            <HelpOutline color="primary" />
                        </Tooltip>
                    )}
                </>
            )}
        >
            {customers
                .filter(({ status }) => status === statusProp)
                .map((customer) => (
                    <CustomerCard
                        key={customer.id}
                        customer={customer}
                    />
                ))}
        </AdminEntityGroup>
    );
}
