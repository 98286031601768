import {
    LLPAMatrixLoanProperty,
    LLPAOnlyLoanProperty,
    LoanProperty,
    llpaLoanPropertyEnumDisplays
} from '@api';
import { AddCircleOutline, Edit, MoreVert } from '@mui/icons-material';
import {
    Autocomplete, Menu, MenuItem, TextField, Tooltip
} from '@mui/material';
import { IconButton } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import { tooltipTitle } from '@utils';
import {
    formatNumericMatrixEntry,
    numericMatrixEntryValidationRules,
    parseNumericMatrixEntry
} from '@utils/numeric-range-utils';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import {
    LLPAMatrixFormCell,
    MatrixStepFormValues,
    getDefaultLlpaMatrixFormCells
} from '@views/admin/llpas/components/LLPADialog';
import matrixStyles from '@views/admin/llpas/components/LLPAMatrix/LLPAMatrix.module.scss';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import {
    useCallback, useContext, useMemo, useRef, useState
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import ActionsCell from './ActionsCell';


export interface LoanPropertyValueCellProps {
    cell: LLPAMatrixFormCell;
    variant: 'row' | 'column';
    property: LLPAMatrixLoanProperty;
    rowProperty: LLPAMatrixLoanProperty | null;
    colProperties: LLPAMatrixLoanProperty[];
    numRows: number;
    cellIndex: number;
    disableInsert: boolean;
    rowSpan?: number;
    hideBottomBorder?: boolean;
    readOnly?: boolean;
    isOnlyRow?: boolean;
}

export default function LoanPropertyValueCell({
    cell, rowSpan, hideBottomBorder, variant, property, rowProperty,
    colProperties, numRows, cellIndex, readOnly, isOnlyRow, disableInsert
}: LoanPropertyValueCellProps) {
    const value = cell[property];
    const { getValues, setValue } = useFormContext<MatrixStepFormValues>();

    const cellRef = useRef<HTMLTableCellElement>(null);
    const [ moreActionsAnchorEl, setMoreActionsAnchorEl ] = useState<HTMLElement>();
    const [ isEditing, setIsEditing ] = useState(!value);
    const [ editingManuallyTriggered, setEditingManuallyTriggered ] = useState(!isEditing);

    const updateValue = useCallback((newValue: typeof value) => {
        if (!rowProperty) {
            setValue(`llpaCells.${cellIndex}.${property}`, newValue as never);
        } else {
            setValue('llpaCells', getValues('llpaCells').map(cellToTest => {
                const shouldUpdate = variant === 'row'
                    ? cellToTest[rowProperty] === cell[rowProperty]
                    : colProperties.slice(0, colProperties.indexOf(property) + 1).every(colProperty => (
                        deepEqual(cellToTest[colProperty], cell[colProperty])
                    ));

                return !shouldUpdate ? cellToTest : {
                    ...cellToTest,
                    [property]: newValue
                };
            }));
        }

        setIsEditing(false);
        setEditingManuallyTriggered(false);
    }, [
        rowProperty, setValue, cellIndex, property, getValues, variant, cell, colProperties
    ]);

    function insertCell(direction: 'before' | 'after') {
        const cells = getValues('llpaCells');

        if (variant === 'row') {
            const startIndex = direction === 'before' ? cellIndex : cellIndex + numRows;

            cells.splice(startIndex, 0, ...cells.slice(0, numRows).map(cell => ({
                ...cell,
                [property]: undefined,
                adjustment: '0.000'
            })));

            setValue('llpaCells', [ ...cells ]);
        } else {
            const insertionIndex = direction === 'before' ? cellIndex : cellIndex + (rowSpan || 1);

            const matrixEntries: LLPAMatrixFormCell[][] = [];
            for (let i = 0; i < cells.length; i += numRows) {
                matrixEntries.push(cells.slice(i, i + numRows));
            }

            const emptyProperties = colProperties.slice(colProperties.indexOf(property));
            const emptyOverrides = Object.fromEntries(emptyProperties.map((colProperty) => (
                [ colProperty, undefined ]
            ))) as Partial<LLPAMatrixFormCell>;

            for (const entriesForRowValue of matrixEntries) {
                const baseEntry = entriesForRowValue[direction === 'before'
                    ? insertionIndex
                    : insertionIndex - 1
                ];

                entriesForRowValue.splice(insertionIndex, 0, {
                    ...baseEntry,
                    ...emptyOverrides,
                    adjustment: '0.000'
                });
            }

            setValue('llpaCells', matrixEntries.flat());
        }
    }

    function removeCell() {
        const previousCols = colProperties.slice(0, colProperties.indexOf(property));

        const newCells = getValues('llpaCells').filter(cellToFilter => {
            const cellNotEqual = !deepEqual(cellToFilter[property], cell[property]);

            return variant === 'row' ? cellNotEqual : (
                cellNotEqual || previousCols.some((col) => !deepEqual(cellToFilter[col], cell[col]))
            );
        });

        setValue('llpaCells', newCells.length
            ? newCells
            : getDefaultLlpaMatrixFormCells(colProperties.concat(rowProperty || [])));
    }

    const investorOptions = useInvestorOptions();
    const displayEnum = llpaLoanPropertyEnumDisplays[property];
    const formatter = isFormattedProperty(property) ? loanPropertyEnumNumericFormatters[property] : undefined;
    const formattedValue = isEditing ? null : !value ? '--' : typeof value === 'string'
        ? formatNumericMatrixEntry(parseNumericMatrixEntry(value)!, formatter)
        : !value.length
            ? '--'
            : value.map((val) => (
                <div key={val}>
                    {property === LLPAOnlyLoanProperty.INVESTOR
                        ? investorOptions?.[val]
                        : displayEnum?.[val as keyof typeof displayEnum]}
                </div>
            ));

    const CellEditor = loanPropertyCellEditors[property];

    return (
        <ActionsCell
            component="th"
            ref={cellRef}
            rowSpan={rowSpan}
            isEditing={isEditing}
            showActions={!!moreActionsAnchorEl}
            className={clsx(matrixStyles.loanPropertyValueCell, {
                [matrixStyles.hideBottomBorder]: hideBottomBorder
            })}
            actions={!readOnly && !isEditing && (
                <>
                    <IconButton
                        size="small"
                        tooltip="Edit cell"
                        onClick={() => {
                            setEditingManuallyTriggered(true);
                            setIsEditing(true);
                        }}
                    >
                        <Edit
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>

                    <IconButton
                        size="small"
                        onClick={() => insertCell('after')}
                        disabled={disableInsert}
                        tooltip={tooltipTitle({
                            'Please fill all empty values in the matrix to add more cells': disableInsert,
                            [`Insert cell ${variant === 'column' ? 'below' : 'after'}`]: true
                        })}
                    >
                        <AddCircleOutline
                            color="success"
                            fontSize="small"
                        />
                    </IconButton>

                    <IconButton
                        size="small"
                        tooltip="More actions"
                        onClick={(event) => setMoreActionsAnchorEl(event.currentTarget)}
                    >
                        <MoreVert
                            color="action"
                            fontSize="small"
                        />
                    </IconButton>
                </>
            )}
        >
            {!isEditing ? formattedValue : (
                <CellEditor
                    property={property}
                    cellIndex={cellIndex}
                    updateValue={updateValue}
                    colProperties={colProperties}
                    autoFocus={editingManuallyTriggered || (variant === 'row' && !isOnlyRow)}
                />
            )}

            <Menu
                open={!!moreActionsAnchorEl}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                anchorEl={moreActionsAnchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <MenuItem
                    onClick={() => {
                        removeCell();
                        setMoreActionsAnchorEl(undefined);
                    }}
                >
                    Remove cell
                </MenuItem>

                <Tooltip
                    title={tooltipTitle({
                        'Please fill all empty values in the matrix to add more cells': disableInsert
                    })}
                >
                    <span>
                        <MenuItem
                            disabled={disableInsert}
                            onClick={() => {
                                insertCell('before');
                                setMoreActionsAnchorEl(undefined);
                            }}
                        >
                            Insert cell {variant === 'column' ? 'above' : 'before'}
                        </MenuItem>
                    </span>
                </Tooltip>
            </Menu>
        </ActionsCell>
    );
}

function formatPercent(value: number | undefined) {
    return !value ? '' : `${value.toFixed(2)}%`;
}

const loanPropertyEnumNumericFormatters = {
    [LoanProperty.LTV]: formatPercent,
    [LoanProperty.CLTV]: formatPercent,
    [LoanProperty.DTI]: formatPercent,
    [LoanProperty.LOAN_AMOUNT]: formatCurrency
} as const;

type FormattedLoanProperty = keyof typeof loanPropertyEnumNumericFormatters;

function isFormattedProperty(loanProperty: LLPAMatrixLoanProperty): loanProperty is FormattedLoanProperty {
    return Object.keys(loanPropertyEnumNumericFormatters).includes(loanProperty);
}

interface MatrixCellEditorProps {
    property: LLPAMatrixLoanProperty;
    cellIndex: number;
    updateValue: (newValue: string | string[] | undefined) => void;
    colProperties: LLPAMatrixLoanProperty[];
    autoFocus: boolean;
}

const { value: numericMatrixRegex, message: numericMatrixPatternError } = numericMatrixEntryValidationRules.pattern;

function NumericMatrixCellEditor({
    property, cellIndex, updateValue, colProperties, autoFocus
}: MatrixCellEditorProps) {
    const formCell = useWatch<MatrixStepFormValues, `llpaCells.${number}`>({
        name: `llpaCells.${cellIndex}`
    });

    const [ value, setValue ] = useState(formCell[property] as string | undefined);
    const [ error, setError ] = useState<string>();

    function validate(valueToValidate: typeof value) {
        const isPopulated = valueToValidate?.length;
        const isValid = isPopulated && !!valueToValidate?.match(numericMatrixRegex);

        setError(!isPopulated
            ? 'This field is required'
            : !isValid ? numericMatrixPatternError : undefined);

        return isValid;
    }

    const firstEmptyColProperty = colProperties.find(colProperty => !formCell[colProperty]?.length);

    return (
        <TextField
            value={value}
            size="small"
            variant="standard"
            autoFocus={autoFocus || firstEmptyColProperty === property}
            required
            error={!!error}
            helperText={error}
            onChange={(event) => {
                const { value } = event.target;
                setValue(value);

                if (error) {
                    validate(value);
                }
            }}
            onFocus={(event) => {
                event.target.select();
            }}
            onBlur={() => {
                if (validate(value)) {
                    updateValue(value);
                }
            }}
        />
    );
}

function EnumMatrixCellEditor(props: MatrixCellEditorProps) {
    return (
        <AutocompleteMatrixCellEditor
            options={llpaLoanPropertyEnumDisplays[props.property]}
            {...props}
        />
    );
}

function InvestorMatrixCellEditor(props: MatrixCellEditorProps) {
    const options = useInvestorOptions();

    return (
        <AutocompleteMatrixCellEditor
            options={options}
            {...props}
        />
    );
}

export function useInvestorOptions() {
    const { investors } = useContext(LLPARoutesContext);

    return useMemo(() => (
        !investors ? null : Object.fromEntries(
            investors.map((investor) => (
                [ investor.id, `${investor.name} (${investor.code})` ]
            ))
        )
    ), [ investors ]);
}

interface AutocompleteMatrixCellEditorProps extends MatrixCellEditorProps {
    options: null | {
        [key: string]: string;
    };
}

function AutocompleteMatrixCellEditor({
    property, cellIndex, updateValue, options, colProperties, autoFocus
}: AutocompleteMatrixCellEditorProps) {
    const cells = useWatch<MatrixStepFormValues, 'llpaCells'>({
        name: 'llpaCells'
    });

    const formCell = useWatch<MatrixStepFormValues, `llpaCells.${number}`>({
        name: `llpaCells.${cellIndex}`
    });

    const formValue = formCell[property] as string[] | undefined;

    const [ value, setValue ] = useState(formValue || []);
    const [ error, setError ] = useState<string>();

    function validate(valueToValidate: typeof value) {
        const isValid = valueToValidate.length;

        setError(isValid ? undefined : 'This field is required');

        return isValid;
    }

    const firstEmptyColProperty = colProperties.find(colProperty => !formCell[colProperty]?.length);

    return !options ? null : (
        <Autocomplete<string, true>
            value={value}
            options={Object.keys(options)}
            getOptionLabel={(value) => options[value as keyof typeof options]}
            size="small"
            multiple
            fullWidth={false}
            filterSelectedOptions
            noOptionsText="All options have been selected"
            openOnFocus
            classes={{ inputRoot: matrixStyles.autocompleteInputRoot }}
            onChange={(_, value) => {
                setValue(value);

                if (error) {
                    validate(value);
                }
            }}
            onBlur={() => {
                if (validate(value)) {
                    updateValue(value);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={false}
                    variant="standard"
                    autoFocus={autoFocus || (cells.length > 1 && firstEmptyColProperty === property)}
                    error={!!error}
                    helperText={error}
                    required
                />
            )}
        />
    );
}

const loanPropertyCellEditors = {
    [LoanProperty.LOAN_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.OCCUPANCY]: EnumMatrixCellEditor,
    [LoanProperty.PURPOSE]: EnumMatrixCellEditor,
    [LoanProperty.AMORT_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.PROPERTY_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.UNITS]: EnumMatrixCellEditor,
    [LoanProperty.FICO]: NumericMatrixCellEditor,
    [LoanProperty.LTV]: NumericMatrixCellEditor,
    [LoanProperty.CLTV]: NumericMatrixCellEditor,
    [LoanProperty.DTI]: NumericMatrixCellEditor,
    [LoanProperty.TERM]: NumericMatrixCellEditor,
    [LoanProperty.AUS]: EnumMatrixCellEditor,
    [LoanProperty.RESERVES_MONTHS]: NumericMatrixCellEditor,
    [LoanProperty.HIGH_BALANCE]: EnumMatrixCellEditor,
    [LoanProperty.SPECIALTY_PROGRAM]: EnumMatrixCellEditor,
    [LoanProperty.STATE]: EnumMatrixCellEditor,
    [LoanProperty.LOAN_AMOUNT]: NumericMatrixCellEditor,
    [LLPAOnlyLoanProperty.INVESTOR]: InvestorMatrixCellEditor
};
