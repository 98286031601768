import api, {
    ApprovalStatusEnum, DueDiligenceStep, DueDiligenceStepType, PermissionType, approvalStatusDisplay
} from '@api';
import {
    Autorenew, Error, Lock, PublishedWithChanges, Save, Verified
} from '@mui/icons-material';
import {
    Alert, Button, InputAdornment, Paper, TextField, Tooltip, Typography
} from '@mui/material';
import {
    FileInput, IconButton, Loader, replaceItemById, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { tooltipTitle } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useEffect, useState
} from 'react';

import styles from './DataRequestSection.module.scss';
import { DocumentCard } from './DocumentCard';
import { SpecialEntitySection } from './SpecialEntitySection';


interface DataRequestSectionProps {
    dueDiligenceStep: DueDiligenceStep;
    setDueDiligenceSteps: Dispatch<SetStateAction<DueDiligenceStep[]>>;
    firstSelected?: boolean;
}

export function DataRequestSection(
    { dueDiligenceStep, setDueDiligenceSteps, firstSelected }: DataRequestSectionProps
) {
    const [ unlockLoading, setUnlockLoading ] = useState(false);
    const [ savingNotes, setSavingNotes ] = useState(false);
    const [ notes, setNotes ] = useState(dueDiligenceStep.notes);
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ uploadLoading, setUploadLoading ] = useState(false);

    const { id: clientID, customerId } = useGetCurrentAccount();
    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_DUE_DILIGENCE_STEP ]);

    const locked = dueDiligenceStep.approvalStatus === ApprovalStatusEnum.APPROVED
    || dueDiligenceStep.approvalStatus === ApprovalStatusEnum.SUBMITTED;

    async function unlockDataRequest() {
        if (await confirm('Are you sure you want to unlock this request? This will move the status back to in progress and require resubmission and review.')) {
            setUnlockLoading(true);

            try {
                const updateDueDiligenceStep = await api
                    .customer.dueDiligenceStep.updateDueDiligenceStep(
                        clientID,
                        customerId!,
                        {
                            ...dueDiligenceStep,
                            approvalStatus: ApprovalStatusEnum.IN_PROGRESS
                        }
                    );

                setDueDiligenceSteps(dueDiligenceSteps => replaceItemById(
                    dueDiligenceSteps, updateDueDiligenceStep
                ));

                pageMessage.success('Data request unlocked');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while unlocking the data request', error);
            }

            setUnlockLoading(false);
        }
    }

    async function saveNotes() {
        setSavingNotes(true);

        try {
            const updateDueDiligenceStep = await api
                .customer.dueDiligenceStep.updateDueDiligenceStep(
                    clientID,
                    customerId!,
                    {
                        ...dueDiligenceStep,
                        notes
                    }
                );

            setDueDiligenceSteps(dueDiligenceSteps => replaceItemById(
                dueDiligenceSteps, updateDueDiligenceStep
            ));

            pageMessage.success('Notes saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the notes', error);
        }

        setSavingNotes(false);
    }

    async function submitForApproval() {
        if (await confirm((
            <Typography>
                {notes !== dueDiligenceStep.notes && (
                    <Alert severity="warning">You have unsaved changes to your notes!</Alert>
                )}

                Are you sure you want to submit this data request?
            </Typography>
        ))) {
            setSubmitLoading(true);

            try {
                const updateDueDiligenceStep = await api
                    .customer.dueDiligenceStep.updateDueDiligenceStep(
                        clientID,
                        customerId!,
                        {
                            ...dueDiligenceStep,
                            approvalStatus: ApprovalStatusEnum.SUBMITTED
                        }
                    );

                setDueDiligenceSteps(dueDiligenceSteps => replaceItemById(
                    dueDiligenceSteps, updateDueDiligenceStep
                ));

                pageMessage.success('Data request submitted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while submitting the data request', error);
            }

            setSubmitLoading(false);
        }
    }

    async function uploadFiles(newFiles: File[]) {
        setUploadLoading(true);

        try {
            const newDocs = await api.customer.dueDiligenceStep.uploadDueDiligenceStepDocument(
                clientID, customerId!, dueDiligenceStep.id, newFiles
            );

            setDueDiligenceSteps(dueDiligenceSteps => replaceItemById(
                dueDiligenceSteps, {
                    ...dueDiligenceStep,
                    documents: [
                        ...(dueDiligenceStep.documents || []),
                        ...newDocs
                    ]
                }
            ));

            pageMessage.success(`File${newFiles.length > 1 ? 's' : ''} uploaded`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading documents', error);
        }

        setUploadLoading(false);
    }

    async function onRemoveDocument(documentID: string) {
        try {
            await api.customer.dueDiligenceStep.removeDueDiligenceStepDocument(
                clientID, customerId!, dueDiligenceStep.id, documentID
            );

            const updatedDueDiligenceStep = {
                ...dueDiligenceStep,
                documents: dueDiligenceStep.documents?.filter(({ id }) => id !== documentID)
            };

            setDueDiligenceSteps(dueDiligenceSteps => replaceItemById(
                dueDiligenceSteps, updatedDueDiligenceStep
            ));

            pageMessage.success('Document removed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while removing the document', error);
        }
    }

    useEffect(() => {
        setNotes(dueDiligenceStep.notes);
    }, [ dueDiligenceStep.notes ]);

    return (
        <>
            <div
                className={clsx(styles.leftPanel, {
                    [styles.firstSelected]: firstSelected
                })}
            >
                <Paper
                    className={styles.statusCard}
                    variant="outlined"
                >
                    <Tooltip title={approvalStatusDescriptions[dueDiligenceStep.approvalStatus]}>
                        <span className={styles.statusHeader}>
                            <Typography>
                                {approvalStatusDisplay[dueDiligenceStep.approvalStatus].toUpperCase()}
                            </Typography>

                            {approvalStatusIcons[dueDiligenceStep.approvalStatus]}
                        </span>
                    </Tooltip>

                    {locked && (
                        <IconButton
                            className={styles.lockButton}
                            autoMarginLoader={false}
                            loading={unlockLoading}
                            size="small"
                            onClick={unlockDataRequest}
                            disabled={!canManage}
                            tooltip={canManage ? 'Unlock data request' : 'You do not have permission to unlock this data request'}
                        >
                            <Lock fontSize="small" />
                        </IconButton>
                    )}
                </Paper>

                <div className={styles.documentsSection}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Documents
                    </Typography>

                    {!dueDiligenceStep.documents?.length ? (
                        <Typography variant="body2">
                            No documents have been uploaded yet.
                        </Typography>
                    ) : dueDiligenceStep.documents?.map(document => (
                        <DocumentCard
                            key={document.id}
                            document={document}
                            onRemoveDocument={() => onRemoveDocument(document.id)}
                            compact
                            canManage={canManage}
                            readOnly={locked || submitLoading}
                            readOnlyTooltip={locked ? 'You must unlock this data request to remove documents' : ''}
                        />
                    ))}
                </div>

                <FileInput
                    title="document"
                    acceptedFileTypes={[ 'pdf' ]}
                    onAddFiles={uploadFiles}
                    disabled={locked || submitLoading || !canManage}
                />
            </div>

            <div className={styles.righthandContent}>
                {dueDiligenceStep.type !== DueDiligenceStepType.CUSTOM && (
                    <SpecialEntitySection
                        type={dueDiligenceStep.type}
                        readonly={locked || submitLoading}
                        readonlyTooltip={locked ? 'You must unlock this data request to make changes' : ''}
                    />
                )}

                <TextField
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    name="notes"
                    label="Notes"
                    multiline
                    rows={4}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={styles.endAdornment}
                            >
                                <IconButton
                                    tooltip={locked ? 'You must unlock this data request to edit notes' : !canManage ? 'You do not have permission to edit notes for this data request' : 'Save notes'}
                                    disabled={locked || submitLoading || !canManage}
                                    autoMarginLoader={false}
                                    loading={savingNotes}
                                    onClick={saveNotes}
                                >
                                    <Save color="success" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled={locked || submitLoading || !canManage}
                />

                <Tooltip
                    title={tooltipTitle({
                        'You must unlock this data request to submit for approval': locked,
                        'You do not have permission to submit this data request': !canManage
                    })}
                >
                    <span className={styles.submitButton}>
                        <Button
                            disabled={locked || submitLoading || !canManage}
                            variant="contained"
                            onClick={submitForApproval}
                        >
                            Submit For Approval
                        </Button>
                    </span>
                </Tooltip>
            </div>

            {(uploadLoading || submitLoading) && (
                <Loader
                    loading
                    className={styles.loader}
                />
            )}
        </>
    );
}

const approvalStatusIcons = {
    [ApprovalStatusEnum.APPROVED]: (
        <Verified
            fontSize="small"
            color="success"
            className={styles.approvalStatusIcon}
        />
    ),
    [ApprovalStatusEnum.REJECTED]: (
        <Error
            color="error"
            fontSize="small"
            className={styles.approvalStatusIcon}
        />
    ),
    [ApprovalStatusEnum.SUBMITTED]: (
        <PublishedWithChanges
            fontSize="small"
            color="inherit"
            className={clsx(styles.approvalStatusIcon, styles.neutralIcon)}
        />
    ),
    [ApprovalStatusEnum.IN_PROGRESS]: (
        <Autorenew
            fontSize="small"
            className={clsx(styles.approvalStatusIcon, styles.neutralIcon)}
        />
    )
};

const approvalStatusDescriptions = {
    [ApprovalStatusEnum.APPROVED]: 'Data has been reviewed and approved.',
    [ApprovalStatusEnum.REJECTED]: 'Data has been reviewed and rejected. Please review the notes and resubmit.',
    [ApprovalStatusEnum.SUBMITTED]: 'Data has been submitted and is awaiting review.',
    [ApprovalStatusEnum.IN_PROGRESS]: 'Data is currently being worked on and is not ready for review.'
};
