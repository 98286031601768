import {
    ActiveInactiveEnum, License, PermissionType, licenseTypeDisplay
} from '@api';
import {
    Cached, CheckCircleOutline, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import { IconButton } from '@tsp-ui/core/components';
import { useConfirm } from '@tsp-ui/core/utils';
import { useHasPermission } from '@utils/hooks/useHasPermission';

import styles from './LicenseCard.module.scss';
import LicenseDetails from './LicenseDetails';


interface LicenseCardProps {
    license: License;
    onToggleActive: (id: string, status: ActiveInactiveEnum) => void;
    onEditClick: (license: License) => void;
    onRenew: (id: string, renewedThroughYear: number) => void;
    readOnly?: boolean;
}

export default function LicenseCard({
    license, onToggleActive, onEditClick, onRenew, readOnly
}: LicenseCardProps) {
    const {
        id, state, type, licenseNumber, renewedThroughYear, status
    } = license;

    // Date.getMonth() is zero indexed for some reason so November = 10 for month comparison below
    const currentDate = new Date();
    const expired = renewedThroughYear < currentDate.getFullYear();
    const canRenew = expired || (currentDate.getMonth() >= 10 && renewedThroughYear === currentDate.getFullYear());

    const [ canEditLicense ] = useHasPermission([ PermissionType.EDIT_LICENSES ]);

    const confirm = useConfirm();

    function getNewRenewedThroughYear() {
        return currentDate.getMonth() < 10 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
    }

    async function toggleActive() {
        if (status === ActiveInactiveEnum.ACTIVE && await confirm('Are you sure you want to deactivate this license?')) {
            onToggleActive(id, ActiveInactiveEnum.INACTIVE);
        } else {
            onToggleActive(id, ActiveInactiveEnum.ACTIVE);
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography className={styles.title}>
                {licenseTypeDisplay[type]}
            </Typography>

            <div className={styles.cardButtons}>
                {!readOnly && (
                    <IconButton
                        tooltip={canEditLicense ? `${status === ActiveInactiveEnum.ACTIVE ? 'Deactivate' : 'Activate'} license` : `You do not have permission to ${status === ActiveInactiveEnum.ACTIVE ? 'deactivate' : 'activate'} licenses`}
                        onClick={toggleActive}
                        disabled={!canEditLicense}
                    >
                        {status === ActiveInactiveEnum.ACTIVE ? <RemoveCircleOutline color="error" /> : <CheckCircleOutline color="success" />}
                    </IconButton>
                )}

                {!expired && !readOnly && (
                    <IconButton
                        tooltip={canEditLicense ? 'Edit license' : 'You do not have permission to edit licenses'}
                        onClick={() => onEditClick(license)}
                        disabled={!canEditLicense}
                    >
                        <Edit color="primary" />
                    </IconButton>
                )}

                {!readOnly && (
                    <IconButton
                        tooltip={canEditLicense ? (canRenew ? 'Renew license' : 'You can renew this license after Nov 1') : 'You do not have permission to renew licenses'}
                        onClick={() => onRenew(id, getNewRenewedThroughYear())}
                        disabled={!canRenew || !canEditLicense}
                        edge="end"
                    >
                        <Cached color={canRenew ? 'primary' : 'disabled'} />
                    </IconButton>
                )}
            </div>

            <LicenseDetails
                licenseNumber={licenseNumber}
                state={state}
            />

            <Tooltip
                title={expired ? 'License is expired' : ''}
                enterDelay={0}
            >
                <Typography
                    className={styles.expirationDate}
                    variant="caption"
                    color={expired ? 'error' : 'textSecondary'}
                >
                    {expired ? `expired in ${renewedThroughYear}` : `valid through ${renewedThroughYear}`}
                </Typography>
            </Tooltip>
        </Paper>
    );
}
